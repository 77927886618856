import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React from "react";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    marginLeft: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function TooManyUsersModal({ display, handleClose, limit }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [t] = useTranslation("users");

  const handleNavigateToMyAccount = () => {
    navigate("/account");
  };

  return (
    <Dialog open={display} fullWidth>
      <DialogTitle variant="h3">{t("users_limit_reached")}</DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography variant={"body1"}>
          {t("too_many_users")}
          {limit}
        </Typography>
        <Typography variant={"body1"} marginTop={2}>
          {t("change_subscription_to_get_more_employees")}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          component="label"
          onClick={handleNavigateToMyAccount}
        >
          {t("my_account")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
