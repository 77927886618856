import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import { formatDate, replaceObjectWithKey } from "../../../../utils/helpers";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "space-between",
  },
  label: {
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default function ProjectsModal({
  display,
  handleClose,
  projects,
  day,
  handleSaveProjectHours,
}) {
  const classes = useStyles();
  const [projectHours, setProjectHours] = useState(day.project_hours);
  const [totalIsValid, setTotalIsValid] = useState(true);
  const [total, setTotal] = useState(0);

  const { t } = useTranslation("timesheets");

  useEffect(() => validateTotal(projectHours));
  const handleChangeProjectHours = (project, event) => {
    let value = event.target.value;
    value = value.replace(",", ".");
    if (value.startsWith("0") && !value.startsWith("0.")) {
      value = value.substring(1);
    }
    const updatedProjectHours = {
      project_id: project.id,
      hours: value || 0,
    };
    let newProjectHours;
    if (projectHours.find((e) => e.project_id === project.id)) {
      newProjectHours = replaceObjectWithKey(
        projectHours,
        updatedProjectHours,
        "project_id"
      );
    } else {
      newProjectHours = [...projectHours, updatedProjectHours];
    }
    setProjectHours(newProjectHours);
    validateTotal(newProjectHours);
    // }
  };

  const handleSave = () => {
    handleSaveProjectHours(projectHours, day);
    handleClose();
  };

  const validateTotal = (newProjectHours) => {
    const totalHours = newProjectHours.reduce((acc, project) => {
      return acc + parseFloat(project.hours);
    }, 0);
    if (totalHours <= parseFloat(day.worked_hours)) {
      setTotalIsValid(true);
    } else setTotalIsValid(false);

    setTotal(totalHours);
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      <DialogTitle variant="h4">
        {t("projects_modal.title")} {formatDate(day.date)}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InfoBox level={"info"}>{t("projects_modal.info")}</InfoBox>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"right"}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.label}
              textAlign={"right"}
            >
              {t("projects_modal.total")}
              {" : "} {total} {"h"} {t("on")} {parseFloat(day.worked_hours)}
            </Typography>
          </Grid>
          {projects.map((project) => (
            <React.Fragment key={project.id}>
              <Grid item xs={12} sm={8} display={"flex"}>
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.label}
                >
                  {project.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  type="text"
                  value={
                    projectHours.find((e) => e.project_id === project.id)
                      ? projectHours.find((e) => e.project_id === project.id)
                          ?.hours
                      : 0
                  }
                  InputProps={{ inputProps: { min: 0, max: 24 } }}
                  onChange={(event) => handleChangeProjectHours(project, event)}
                />
              </Grid>
            </React.Fragment>
          ))}
          {!totalIsValid && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="strong"
                color="error"
                textAlign="left"
              >
                {t("projects_modal.total_invalid")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          onClick={handleClose}
        >
          {t("projects_modal.cancel_button")}
        </Button>
        <Button
          variant="contained"
          component="label"
          color="primary"
          disabled={!totalIsValid}
          onClick={handleSave}
        >
          {t("projects_modal.confirm_button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
