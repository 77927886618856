import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../../utils/helpers";
import Typography from "@mui/material/Typography";

export default function FiscalYear({ dateFrom, dateTo, status, weekFirstDay }) {
  const { t, i18n } = useTranslation("myAccount");
  const locale = i18n.language;
  const now = new Date();
  return (
    <Grid item sm={12}>
      <Typography variant="body2">
        {t("From")} {formatDate(dateFrom, locale)} {t("to")}{" "}
        {formatDate(dateTo, locale)} :{" "}
        {new Date(dateFrom) <= now
          ? new Date(dateTo) < now && status !== "archived"
            ? t("past")
            : t(status)
          : t("future")}
      </Typography>
      <Typography variant="body1" marginTop={1}>
        {t("fiscal_years.week_starts_on.title")}
        {t(`fiscal_years.week_starts_on.${weekFirstDay}`)}
      </Typography>
    </Grid>
  );
}
