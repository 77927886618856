import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import { useTranslation } from "react-i18next";
import CardContent from "@mui/material/CardContent";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
  },
  perPeriod: {
    color: theme.palette.primary.darkest,
  },
  price: {
    fontWeight: 600,
  },
  features: {
    listStyle: "none",
    paddingLeft: "0.5rem",
  },
  feature: {
    "&::before": {
      content: '"✅ "',
      fontSize: "1rem",
      marginRight: "0.5rem",
    },
    marginBottom: "0.75rem",
  },
  notFeature: {
    "&::before": {
      content: '"❌️ "',
      fontSize: "1rem",
      marginRight: "0.5rem",
    },
    marginBottom: "0.75rem",
  },
  cardContent: {
    padding: "2rem",
  },
}));

export default function Plan({ plan, selected, onClick }) {
  const [t] = useTranslation("myAccount");

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} key={plan.id}>
      <Card raised={selected} className={classes.card} fullWidth>
        <CardActionArea onClick={() => onClick(plan.id)}>
          <CardHeader title={t(`plans.${plan.name}.name`)} />
          <CardContent>
            <Grid container spacing={2} className={classes.cardContent}>
              <Grid item xs={12}>
                <Typography variant="body1" component="p">
                  {t(`plans.${plan.name}.description`)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ul className={classes.features}>
                  {plan.features.map((feature) => (
                    <Typography
                      variant="body1"
                      component="li"
                      key={feature}
                      className={classes.feature}
                    >
                      {t(`plans.features.${feature}`)}
                    </Typography>
                  ))}
                  {plan.notFeatures.map((feature) => (
                    <Typography
                      variant="body1"
                      component="li"
                      key={feature}
                      className={classes.notFeature}
                    >
                      {t(`plans.features.${feature}`)}
                    </Typography>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  component="span"
                  className={classes.price}
                >
                  {t(`plans.${plan.name}.price`)}{" "}
                </Typography>

                <Typography
                  variant="subtitle1"
                  component="span"
                  className={classes.perPeriod}
                >
                  {plan.period === "year"
                    ? t("plans.per_year")
                    : t("plans.per_month")}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
