import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { getUsers, getTeams } from "../../../slices/accountSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { setLoading } from "../../../slices/feedbackSlice";
import CreateTeamModal from "./CreateTeamModal/CreateTeamModal";
import DeleteTeamModal from "./DeleteTeamModal/DeleteTeamModal";
import UpdateTeamModal from "./UpdateTeamModal/UpdateTeamModal";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
  nameCell: {
    maxWidth: "10rem",
    fontWeight: "500",
  },
  usersCell: {
    maxWidth: "30rem",
  },
  editCell: {
    textAlign: "center",
    width: "6rem",
  },
  name: {
    marginRight: "1rem",
    display: "inline-block",
  },
  icon: {
    cursor: "pointer",
  },
}));

export default function Teams() {
  const classes = useStyles();
  const { t } = useTranslation("teams");
  const dispatch = useDispatch();

  const users = useSelector((state) => state.account.users);
  const teams = useSelector((state) => state.account.teams);

  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const [updateTeamModalOpen, setUpdateTeamModalOpen] = useState(false);
  const [editedTeam, setEditedTeam] = useState({});
  const [deleteTeamModalOpen, setDeleteTeamModalOpen] = useState(false);
  const [toDeleteTeam, setToDeleteTeam] = useState({});

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getTeams())
      .unwrap()
      .then((response) => dispatch(setLoading(false)))
      .catch((err) => dispatch(setLoading(false)));
    dispatch(getUsers({ displayArchived: true }));
  }, []);

  const handleAddTeamModalClose = () => {
    setCreateTeamModalOpen(false);
  };
  const handleAddProjectModalOpen = () => {
    setCreateTeamModalOpen(true);
  };
  const handleUpdateTeamModalClose = () => {
    setUpdateTeamModalOpen(false);
  };

  const handleUpdateTeamModalOpen = (team) => {
    setEditedTeam(team);
    setUpdateTeamModalOpen(true);
  };
  const handleDeleteTeamModalClose = () => {
    setDeleteTeamModalOpen(false);
  };

  const handleDeleteTeamModalOpen = (team) => {
    setToDeleteTeam(team);
    setDeleteTeamModalOpen(true);
  };

  const displayUserLabelsFromIds = (userIds) => {
    const displayedUsers = users.filter((user) => userIds.includes(user.id));
    return displayedUsers.map((user) => (
      <span key={user.id} className={classes.name}>
        {user.full_name}
      </span>
    ));
  };

  return (
    <>
      {createTeamModalOpen && (
        <CreateTeamModal
          display={createTeamModalOpen}
          handleClose={handleAddTeamModalClose}
          users={users}
        />
      )}
      {deleteTeamModalOpen && (
        <DeleteTeamModal
          display={deleteTeamModalOpen}
          handleClose={handleDeleteTeamModalClose}
          team={toDeleteTeam}
        />
      )}
      {updateTeamModalOpen && (
        <UpdateTeamModal
          display={updateTeamModalOpen}
          handleClose={handleUpdateTeamModalClose}
          team={editedTeam}
          users={users}
        />
      )}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} marginBottom={2}>
          <Typography component="h2" variant="h2">
            {t("teams")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            onClick={handleAddProjectModalOpen}
          >
            {t("create_team")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label={t("teams")}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.nameCell}>
                    {t("name")}
                  </TableCell>
                  <TableCell className={classes.usersCell}>
                    {t("admins")}
                  </TableCell>
                  <TableCell className={classes.usersCell}>
                    {t("users")}
                  </TableCell>
                  <TableCell className={classes.editCell}>
                    {t("edit")}
                  </TableCell>
                  <TableCell className={classes.editCell}>
                    {t("delete")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.map((team) => {
                  return (
                    <TableRow hover key={team.id}>
                      <TableCell className={classes.nameCell}>
                        {team.name}
                      </TableCell>
                      <TableCell className={classes.usersCell}>
                        {displayUserLabelsFromIds(team.admins)}
                      </TableCell>
                      <TableCell className={classes.usersCell}>
                        {displayUserLabelsFromIds(team.users)}
                      </TableCell>
                      <TableCell className={classes.editCell}>
                        <EditIcon
                          className={classes.icon}
                          onClick={() => handleUpdateTeamModalOpen(team)}
                        />
                      </TableCell>
                      <TableCell className={classes.editCell}>
                        <DeleteForeverIcon
                          onClick={() => handleDeleteTeamModalOpen(team)}
                          className={classes.icon}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
