import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import OrganizationConfig from "./OrganizationConfig/OrganizationConfig";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import AddIcon from "@mui/icons-material/Add";

export default function OrganizationRules({ organization, configs }) {
  const [newConfig, setNewConfig] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(false);

  const { t } = useTranslation("myAccount");

  useEffect(() => {
    const currentConfigs = configs.filter((config) => config.is_current);
    setCurrentConfig(currentConfigs[0] || configs[0]);
  }, [configs]);

  const toggleNewConfig = () => {
    setNewConfig(!newConfig);
  };

  return (
    <Card>
      <CardHeader
        title={t("rules.rules")}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoBox level={"info"}>{t("rules.description")}</InfoBox>
          </Grid>{" "}
          <Grid item xs={12}>
            {configs.map((config) => (
              <OrganizationConfig config={config} key={config.id} />
            ))}
            {newConfig && (
              <OrganizationConfig
                config={currentConfig}
                key={"new"}
                newConfig={true}
                organizationId={organization.id}
                cancelNewConfig={toggleNewConfig}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <InfoBox level={"info"}>
              {t("organization_rules_info")}
              <br />
              <br />
              <a
                href={
                  "https://feuilledetemps.ca/docs/tutorial-admin/changement-regles-organisme"
                }
                target="_blank"
                rel="noopener"
              >
                {t("organization_rules_documentation")}
              </a>
            </InfoBox>
          </Grid>
          {!newConfig && (
            <Grid item xs={12} display={"flex"} justifyContent={"right"}>
              <Button
                startIcon={<AddIcon />}
                variant={"outlined"}
                color={"darkPrimary"}
                onClick={toggleNewConfig}
              >
                {t("add_rules")}
              </Button>{" "}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
