import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Plan from "./Plan/Plan";
import plans from "../../../../../assets/plans/plans.json";
import Switch from "@mui/material/Switch";

export default function Plans({ selectedPlanId, handleSelectPlan }) {
  const [t] = useTranslation("myAccount");
  const [isPlanAnnual, setIsPlanAnnual] = useState(true);

  return (
    <Grid container spacing={4}>
      {/* <Grid item xs={12}> */}
      {/*   <Typography variant="h5" component="h5"> */}
      {/*     {t("plans.title")} */}
      {/*   </Typography> */}
      {/* </Grid> */}
      {/* <Grid item xs={12}> */}
      {/*   <Typography variant="body1" component="p"> */}
      {/*     {t("plans.instructions")} */}
      {/*   </Typography> */}
      {/* </Grid> */}
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          {t("plans.monthly")}
        </Typography>
        <Switch
          onChange={() => setIsPlanAnnual(!isPlanAnnual)}
          checked={isPlanAnnual}
        />
        <Typography variant="h5" component="span">
          {t("plans.annual")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {Object.values(plans.plans)
            .filter((plan) => {
              if (isPlanAnnual) {
                return plan.period === "year";
              } else {
                return plan.period === "month";
              }
            })
            .map((plan) => (
              <>
                <Plan
                  key={plan.id}
                  selected={selectedPlanId === plan.id}
                  plan={plan}
                  onClick={handleSelectPlan}
                />
              </>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
