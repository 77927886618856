import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";

import { displayAlert } from "../../../../../slices/feedbackSlice";
import TimesheetsService from "../../../../../services/timesheets.service";
import { InfoBox } from "../../../../Layout/InfoBox/InfoBox";
import TextField from "@mui/material/TextField";
import Simulation from "./Simulation/Simulation";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    justifyContent: "space-between",
  },
}));

export default function SimulationModal({ display, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [userSummary, setUserSummary] = useState({});

  const { t } = useTranslation("timesheets");

  const handleSuccess = (result) => {
    setUserSummary(result.data);
    setLoading(false);
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("simulation_modal.error"), "error"));
    setLoading(false);
  };

  const handleCalculateSimulation = () => {
    setLoading(true);
    TimesheetsService.getSummaryForSelf(date)
      .then((result) => handleSuccess(result))
      .catch((error) => handleError(error));
  };

  const handleChangeDate = (event) => {
    setDate(event.target.value);
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      <DialogTitle variant="h4">
        {t("simulation_modal.title")}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InfoBox level={"info"}>
              {t("simulation_modal.explanation_1")}
              <br />
              <br />
              {t("simulation_modal.explanation_2")}
            </InfoBox>
          </Grid>
          {userSummary.vacation_hours !== undefined && (
            <Grid item xs={12} marginBottom={"20px"}>
              <Simulation userSummary={userSummary} />
            </Grid>
          )}
          <Grid item xs={12} display={"flex"} justifyContent={"space-evenly"}>
            <TextField type={"date"} value={date} onChange={handleChangeDate} />

            <LoadingButton
              variant="contained"
              component="label"
              color="primary"
              onClick={handleCalculateSimulation}
              loading={loading}
              sx={{ height: "100%" }}
            >
              {t("simulation_modal.simulate_button")}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
