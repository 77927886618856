import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Navigate, useLocation } from "react-router-dom";
import { login, setHasAccount } from "../../../slices/authSlice";
import { displayAlert, setLoading } from "../../../slices/feedbackSlice";
import { getInvitedUser, signupUser } from "../../../slices/accountSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: "center",
    color: theme.palette.error.main,
    textTransform: "title",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function UserSignup(props) {
  const { t } = useTranslation("signin");

  const [fields, setFields] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const { isLoggedIn } = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.feedback.loading);
  const invitedUser = useSelector((state) => state.account.invitedUser);

  const search = useLocation().search;

  useEffect(() => {
    const token = new URLSearchParams(search).get("code");
    if (token) {
      dispatch(getInvitedUser(token));
      localStorage.clear();
      dispatch(setHasAccount(true));
    }
  }, []);

  useEffect(() => {
    setFields({
      email: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "email",
        label: t("email_address"),
        name: "email",
        autoComplete: "email",
        value: invitedUser?.email || "",
        InputLabelProps: { shrink: true },
      },

      firstName: {
        autoComplete: "firstName",
        name: "firstName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "firstName",
        label: t("firstName"),
        value: invitedUser?.first_name || "",
        InputLabelProps: { shrink: true },
      },
      lastName: {
        autoComplete: "lastName",
        name: "lastName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "lastName",
        label: t("lastName"),
        value: invitedUser?.last_name || "",
        InputLabelProps: { shrink: true },
      },
      organization: {
        autoComplete: "organization",
        name: "organization",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "organization",
        label: t("organization"),
        value: invitedUser?.organization || "",
        InputLabelProps: { shrink: true },
      },
      password: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "password",
        label: t("password"),
        type: "password",
        id: "password",
        autoComplete: "current-password",
        value: "",
      },
    });
  }, [t, invitedUser]);

  const handleSignup = (
    firstName,
    lastName,
    email,
    password,
    invitation_token
  ) => {
    setError("");

    dispatch(
      signupUser({
        firstName,
        lastName,
        password,
        invitation_token,
      })
    )
      .unwrap()
      .then(() => {
        setError("");
        dispatch(displayAlert(t("account_created"), "success"));
        dispatch(login({ password, email }))
          .unwrap()
          .then(() => {
            setError("");
            dispatch(setLoading(false));
          });
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    handleSignup(
      fields.lastName.value,
      fields.firstName.value,
      fields.email.value,
      fields.password.value,
      invitedUser.invitation_token
    );
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="p" variant="body1" align={"center"}>
                {t("invited_to")}
              </Typography>
              <Typography
                component="p"
                variant="h5"
                align={"center"}
                margin={"0.5rem"}
              >
                {fields.organization?.value}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.firstName} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.lastName} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.email} disabled />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.password} />
            </Grid>
          </Grid>
          {error && (
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.errorMessage}>
                {error}
              </Grid>
            </Grid>
          )}
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {t("join")}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Container>
  );
}
