import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  infoBoxInfo: {
    display: "flex",
    backgroundColor: theme.palette.primary.lightest,
    padding: "1rem",
  },
  infoBoxError: {
    display: "flex",
    backgroundColor: theme.palette.secondary.lightest,
    padding: "1rem",
  },
  infoButton: {},
  errorButton: {},
  icon: {
    marginRight: "1rem",
  },
}));

export const InfoBox = ({ children, level }) => {
  const styles = useStyles();
  const [hidden, setHidden] = useState(false);
  const [boxStyle, setBoxStyle] = useState({});
  const [icon, setIcon] = useState(false);

  useEffect(() => {
    switch (level) {
      case "info":
        hidden
          ? setBoxStyle(styles.infoButton)
          : setBoxStyle(styles.infoBoxInfo);
        setIcon(<InfoIcon color={"primary"} />);
        break;
      case "error":
        setBoxStyle(styles.infoBoxError);
        setIcon(<ErrorIcon color={"darkSecondary"} />);
        break;
      default:
        hidden
          ? setBoxStyle(styles.infoButton)
          : setBoxStyle(styles.infoBoxInfo);
        setIcon("");
        break;
    }
  }, [hidden, styles, level]);

  const toggleHidden = () => {
    setHidden(!hidden);
  };

  return (
    <div className={boxStyle}>
      <div
        className={styles.icon}
        onKeyPress={() => toggleHidden(true)}
        onClick={() => toggleHidden(true)}
        role={"button"}
        tabIndex={0}
      >
        {icon}
      </div>
      {!hidden && <div>{children}</div>}
    </div>
  );
};
