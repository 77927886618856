import { Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { displayStatus, formatDate, round } from "../../../../../utils/helpers";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: "1rem 0",
  },
  title: {
    color: theme.palette.secondary.dark,
  },
}));

export default function TimesheetSummary({
  timesheet,
  userSummary = {},
  user = {},
}) {
  const { t, i18n } = useTranslation("timesheets");
  const locale = i18n.language;
  const classes = useStyles();
  const navigate = useNavigate();

  const projectsHoursMandatory = useSelector(
    (state) =>
      state.account.organization.projects_option_is_mandatory &&
      user.has_projects
  );

  const hasFamilyHours = user.timesheet_columns?.has_family_hours;
  const familyHoursCustomName =
    user.timesheet_columns?.family_hours_custom_name;

  const displayValues = ["signed", "countersigned"].includes(timesheet.status);

  return (
    <Grid item sm={12} md={6}>
      <Card>
        <CardHeader
          title={`${t("from")} ${formatDate(timesheet.date_from, locale)} ${t(
            "to"
          )} ${formatDate(timesheet.date_to, locale)}`}
          titleTypographyProps={{ variant: "h3" }}
          classes={{
            title: classes.title,
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Typography variant="h5">{t("worked_hours")} :</Typography>
                </Grid>
                <Grid item xs={5}>
                  {displayValues ? (
                    <Typography>
                      {round(timesheet.summary.worked_hours)} {t("hours")} (
                      {round(timesheet.summary.worked_days)} {t("days")})
                    </Typography>
                  ) : (
                    <Typography>---</Typography>
                  )}
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h5">{t("sick")} :</Typography>
                </Grid>
                <Grid item xs={5}>
                  {displayValues ? (
                    <Typography>
                      {round(timesheet.summary.sick_hours)} {t("hours")} (
                      {round(timesheet.summary.sick_days)} {t("days")})
                    </Typography>
                  ) : (
                    <Typography>---</Typography>
                  )}
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h5">{t("vacation")} :</Typography>
                </Grid>
                <Grid item xs={5}>
                  {displayValues ? (
                    <Typography>
                      {round(timesheet.summary.vacation_hours)} {t("hours")} (
                      {round(timesheet.summary.vacation_days)} {t("days")})
                    </Typography>
                  ) : (
                    <Typography>---</Typography>
                  )}
                </Grid>
                {hasFamilyHours && (
                  <>
                    <Grid item xs={7}>
                      <Typography variant="h5">
                        {familyHoursCustomName || t("family_hours")} :
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {displayValues ? (
                        <Typography>
                          {round(timesheet.summary.family_hours || 0)}{" "}
                          {t("hours")} (
                          {round(timesheet.summary.family_days || 0)}{" "}
                          {t("days")}){" "}
                        </Typography>
                      ) : (
                        <Typography>---</Typography>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={7}>
                  {userSummary.has_compensatory_days ? (
                    <Typography variant="h5">{t("compensatory")} :</Typography>
                  ) : (
                    <Typography variant="h5">{t("overtime")} :</Typography>
                  )}
                </Grid>
                <Grid item xs={5}>
                  {userSummary.has_compensatory_days ? (
                    displayValues ? (
                      <Typography>
                        {round(timesheet.summary.compensatory_hours)}{" "}
                        {t("hours")} (
                        {round(timesheet.summary.compensatory_days)} {t("days")}
                        )
                      </Typography>
                    ) : (
                      <Typography>---</Typography>
                    )
                  ) : displayValues ? (
                    <Typography>
                      {round(timesheet.summary.overtime_hours)} {t("hours")} (
                      {round(timesheet.summary.overtime_days)} {t("days")})
                    </Typography>
                  ) : (
                    <Typography>---</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                {displayStatus(timesheet, t, projectsHoursMandatory)}
              </Grid>
              <Grid item sm={3} display={"flex"} justifyContent={"right"}>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  onClick={() => navigate(`/timesheet/${timesheet.id}`)}
                  aria-label={t("details")}
                >
                  {t("details")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
