import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const postTimeReport = (data) => {
  const timeReport = {
    date_from: data.dateFrom,
    date_to: data.dateTo,
    user_ids: data.userIds,
    email_to: data.email,
    name_to: data.name,
  };

  return axios.post(`${API_URL}/reports/time/`, timeReport, {
    headers: authHeader(),
  });
};
const postUserSummariesReport = (data) => {
  const userSummariesReport = {
    date: data.date,
    email_to: data.email,
    name_to: data.name,
  };

  return axios.post(`${API_URL}/reports/user-summaries/`, userSummariesReport, {
    headers: authHeader(),
  });
};

const postProjectReport = (data) => {
  const timeReport = {
    date_from: data.dateFrom, // Not implemented yet
    date_to: data.dateTo, // Not implemented yet
    email_to: data.email,
    name_to: data.name,
  };

  return axios.post(`${API_URL}/reports/projects/`, timeReport, {
    headers: authHeader(),
  });
};
const postTimeOffReport = (data) => {
  const timeOffReport = {
    date_from: data.dateFrom, // Not implemented yet
    date_to: data.dateTo, // Not implemented yet
    email_to: data.email,
    name_to: data.name,
  };

  return axios.post(`${API_URL}/reports/time-off/`, timeOffReport, {
    headers: authHeader(),
  });
};

const organizationService = {
  postTimeReport,
  postProjectReport,
  postUserSummariesReport,
  postTimeOffReport,
};

export default organizationService;
