import { Autocomplete, DialogContent, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: "10px",
  },
}));

export default function WorkingTimeConfig({
  handlePrevious,
  handleNext,
  progress,
  handleChangeConfig,
  config,
}) {
  const classes = useStyles();
  const [workedDays, setWorkedDays] = useState([]);
  const { t } = useTranslation("myAccount");

  const daysOfWeek = [
    { id: 0, label: t("Monday", { ns: "common" }) },
    { id: 1, label: t("Tuesday", { ns: "common" }) },
    { id: 2, label: t("Wednesday", { ns: "common" }) },
    { id: 3, label: t("Thursday", { ns: "common" }) },
    { id: 4, label: t("Friday", { ns: "common" }) },
    { id: 5, label: t("Saturday", { ns: "common" }) },
    { id: 6, label: t("Sunday", { ns: "common" }) },
  ];

  const formatAutocompleteDaysList = (dayIds) => {
    return dayIds.map((id) =>
      daysOfWeek.find((dayOfWeek) => dayOfWeek.id === id)
    );
  };

  useEffect(() => {
    const orderedWorkedDays = [...config.workingTime.workedDays];
    orderedWorkedDays.sort((a, b) => a - b);
    const formattedWorkedDays = formatAutocompleteDaysList(orderedWorkedDays);
    setWorkedDays(formattedWorkedDays);
  }, [config]);

  const handleClickNext = () => {
    handleNext();
  };

  const handleChange = (event, selectedWorkedDays) => {
    let { name, value } = event.target;
    if (selectedWorkedDays) {
      name = "workedDays";
      value = selectedWorkedDays.map((day) => day.id);
    }

    const newWorkingTimeConfig = {
      ...config.workingTime,
      [name]: value,
    };
    handleChangeConfig("workingTime", newWorkingTimeConfig);
  };

  return (
    <>
      <DialogContent>
        <InfoBox level={"info"}>
          {t("config_assistant.global_values_info")}
        </InfoBox>
        <Grid container spacing={3} marginTop={2}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.working_time.1")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={"number"}
              value={config.workingTime.hours}
              InputLabelProps={{ shrink: true }}
              name={"hours"}
              onChange={handleChange}
              label={t("rules.hours")}
            />
          </Grid>
          <Grid item sm={12}>
            <Autocomplete
              multiple
              options={daysOfWeek}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              value={workedDays}
              onChange={handleChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("worked_days", { ns: "common" })}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          component="label"
          color="darkSecondary"
          onClick={handlePrevious}
        >
          {t("config_assistant.previous")}
        </Button>
        <LoadingButton
          variant="contained"
          component="label"
          color="primary"
          onClick={handleClickNext}
        >
          {t("config_assistant.next")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
