import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProjectsService from "../services/projects.service";
import { displayAlert } from "./feedbackSlice";
import { manageError, replaceObjectWithKey } from "../utils/helpers";

export const getProjects = createAsyncThunk(
  "projects/get",
  async (thunkAPI) => {
    try {
      const data = await ProjectsService.getProjects();
      return { projects: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createProject = createAsyncThunk(
  "projects/post",
  async (projectData, thunkAPI) => {
    try {
      const data = await ProjectsService.createProject(projectData);
      return { project: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateProject = createAsyncThunk(
  "projects/put",
  async (projectData, thunkAPI) => {
    try {
      const data = await ProjectsService.updateProject(projectData);
      return { project: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteProject = createAsyncThunk(
  "projects/delete",
  async (projectId, thunkAPI) => {
    try {
      await ProjectsService.deleteProject(projectId);
      return { projectId: projectId };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getProjectSummary = createAsyncThunk(
  "projects/summary/get",
  async (projectId, thunkAPI) => {
    try {
      const data = await ProjectsService.getProjectSummary(projectId);
      return { projectSummary: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    projects: [],
    projectSummaries: {},
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getProjects.fulfilled]: (state, action) => {
      state.projects = action.payload.projects;
    },
    [createProject.fulfilled]: (state, action) => {
      state.projects = [action.payload.project, ...state.projects];
    },
    [updateProject.fulfilled]: (state, action) => {
      const updatedProject = action.payload.project;
      state.projects = replaceObjectWithKey(
        state.projects,
        updatedProject,
        "id"
      );
    },
    [deleteProject.fulfilled]: (state, action) => {
      const projectId = action.payload.projectId;
      state.projects = [...state.projects].filter(
        (project) => project.id !== projectId
      );
    },
    [getProjectSummary.fulfilled]: (state, action) => {
      const projectSummary = action.payload.projectSummary;
      state.projectSummaries[projectSummary.id] = projectSummary;
    },
  },
});

export default projectsSlice.reducer;
