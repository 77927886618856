import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { createTeam } from "../../../../slices/accountSlice";
import { Autocomplete } from "@mui/material";
import { displayAlert } from "../../../../slices/feedbackSlice";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function CreateTeamModal({ display, handleClose, users }) {
  const [loading, setLoading] = useState(false);
  const [teamProperties, setTeamProperties] = useState({});
  const classes = useStyles();
  const { t } = useTranslation("teams");
  const dispatch = useDispatch();

  useEffect(() => {
    setTeamProperties({
      name: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "name",
        label: t("name"),
        name: "name",
        value: "",
      },
      users: {
        value: [],
      },
    });
  }, [t]);

  const handleSubmit = () => {
    setLoading(true);
    const newTeam = {
      name: teamProperties.name.value,
      users: teamProperties.users.value.map((user) => user.id),
      admins: teamProperties.admins.value.map((user) => user.id),
    };
    dispatch(createTeam(newTeam))
      .unwrap()
      .then(() => dispatch(displayAlert(t("create_team_success"), "success")))
      .catch((err) => dispatch(displayAlert(t("error"), "error")));
    setLoading(false);
    handleClose();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { value, name } = target;
    if (name) {
      setTeamProperties({
        ...teamProperties,
        [name]: {
          ...teamProperties[name],
          value,
        },
      });
    }
  };

  const handleChangeUsers = (event, selectedUsers) => {
    event.preventDefault();
    setTeamProperties({
      ...teamProperties,
      users: {
        value: selectedUsers,
      },
    });
  };
  const handleChangeAdmins = (event, selectedUsers) => {
    event.preventDefault();
    setTeamProperties({
      ...teamProperties,
      admins: {
        value: selectedUsers,
      },
    });
  };

  const modalContent = (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {t("create_team_modal.title")}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} key={teamProperties?.name?.id}>
              <TextField {...teamProperties.name} />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={users}
                getOptionLabel={(option) => option.full_name}
                filterSelectedOptions
                value={teamProperties?.admins?.value}
                onChange={handleChangeAdmins}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("admins")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={users}
                getOptionLabel={(option) => option.full_name}
                filterSelectedOptions
                value={teamProperties?.users?.value}
                onChange={handleChangeUsers}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t("users")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          disabled={loading}
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t("create_team_modal.save_button")}
        </LoadingButton>
      </DialogActions>
    </>
  );

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      {modalContent}
    </Dialog>
  );
}
