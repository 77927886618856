import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";
import DialogActions from "@mui/material/DialogActions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { BOOK_DEMO_URL } from "../../../config/config";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "right",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: "15px",
  },
  bookDemoButton: {
    marginTop: theme.spacing(2),
  },
}));

export default function NextSteps() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation("myAccount");

  const handleBookDemo = () => {
    window.open(BOOK_DEMO_URL);
  };

  const handleClickTeamPage = () => {
    navigate("/users");
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.next_steps.1")}
            </Typography>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.next_steps.2")}
            </Typography>

            <InfoBox level={"info"}>
              {t("config_assistant.next_steps.demo_1")}
              <br />
              <br />
              {t("config_assistant.next_steps.demo_2")}
              <br />
              <Button
                variant="outlined"
                color="darkPrimary"
                className={classes.bookDemoButton}
                onClick={handleBookDemo}
                endIcon={<OpenInNewIcon />}
              >
                {t("config_assistant.next_steps.book_demo")}
              </Button>
            </InfoBox>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          component="label"
          color="primary"
          onClick={handleClickTeamPage}
        >
          {t("config_assistant.next_steps.team_page")}
        </Button>
      </DialogActions>
    </>
  );
}
