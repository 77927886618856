const white = "#FFFFFF";
const black = "#000000";

const grey = {
  main: "rgba(104,117,106,1)",
  light: "rgba(104,117,106,0.23)",
};

const primary = {
  main: "#acd0ce",
  lightest: "#f8fcfb",
  darkest: "rgba(0,44,36,0.63)",
};

const secondary = {
  main: "#FF9CA1",
  lightest: "#FFF6F7",
  contrastText: "#0D0604",
};

const darkSecondary = {
  main: "#BF4D48",
  contrastText: "#FF9CA1",
};

const darkPrimary = {
  main: "#002c24",
  contrastText: "#acd0ce",
};

const text = {
  primary: "#002c24",
  secondary: "#962826",
  error: "#d04840",
};

const palette = {
  type: "light",
  text,
  grey,
  primary: primary,
  secondary: secondary,
  darkPrimary,
  darkSecondary,
  white,
  black,
  background: {
    default: primary.lightest,
  },
  contrastThreshold: 4,
};

export default palette;
