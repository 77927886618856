import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import ReportsService from "../../../services/reports.service";
import { displayAlert } from "../../../slices/feedbackSlice";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
}));

export default function Reports() {
  const classes = useStyles();
  const { t } = useTranslation("reports");
  const dispatch = useDispatch();

  const users = useSelector((state) => state.account.users);
  const organization = useSelector((state) => state.account.organization);

  const [loading, setLoading] = useState(false);
  const [isTimeFormValid, setIsTimeFormValid] = useState(false);
  const [isProjectsFormValid, setIsProjectsFormValid] = useState(false);
  const [isUserSummariesFormValid, setIsUserSummariesFormValid] =
    useState(false);
  const [isTimeOffFormValid, setIsTimeOffFormValid] = useState(false);

  const [timeReport, setTimeReport] = useState({
    email: "",
    name: "",
    dateFrom: null,
    dateTo: null,
    userIds: [...Array(1000).keys()],
  });

  const [projectsReport, setProjectsReport] = useState({
    email: "",
    name: "",
    dateFrom: "2020-01-01",
    dateTo: "2029-01-01", // default dates until we implement the front
  });

  const [userSummariesReport, setUserSummariesReport] = useState({
    email: "",
    name: "",
    date: null,
  });

  const [timeOffReport, setTimeOffReport] = useState({
    email: "",
    name: "",
    dateFrom: null,
    dateTo: null,
  });

  useEffect(() => {
    const userIds = users.map((user) => user.id);
    const newReport = { ...timeReport, userIds: userIds };
    setTimeReport(newReport);
  }, [users]);

  const handleChangeTime = (event) => {
    const { name, value } = event.target;
    const newTimeReport = { ...timeReport, [name]: value };
    setTimeReport(newTimeReport);
    setIsTimeFormValid(
      !!newTimeReport.name &&
        !!newTimeReport.email &&
        !!newTimeReport.dateFrom &&
        !!newTimeReport.dateTo
    );
  };

  const handleChangeTimeDate = (value, name) => {
    const newTimeReport = { ...timeReport, [name]: value };
    setTimeReport(newTimeReport);
    setIsTimeFormValid(
      !!newTimeReport.name &&
        !!newTimeReport.email &&
        !!newTimeReport.dateFrom &&
        !!newTimeReport.dateTo
    );
  };

  const handleChangeUserSummaries = (event) => {
    const { name, value } = event.target;
    const newUserSummariesReport = { ...userSummariesReport, [name]: value };
    setUserSummariesReport(newUserSummariesReport);
    setIsUserSummariesFormValid(
      !!newUserSummariesReport.name &&
        !!newUserSummariesReport.email &&
        !!newUserSummariesReport.date
    );
  };
  const handleChangeUserSummariesDate = (value, name) => {
    const newUserSummariesReport = { ...userSummariesReport, [name]: value };
    setUserSummariesReport(newUserSummariesReport);
    setIsUserSummariesFormValid(
      !!newUserSummariesReport.name &&
        !!newUserSummariesReport.email &&
        !!newUserSummariesReport.date
    );
  };

  const handleChangeProjects = (event) => {
    const { name, value } = event.target;
    const newProjectReport = { ...projectsReport, [name]: value };
    setProjectsReport(newProjectReport);
    setIsProjectsFormValid(
      !!newProjectReport.name && !!newProjectReport.email
      // !!newProjectReport.dateFrom &&
      // !!newProjectReport.dateTo
    );
  };

  const handleChangeTimeOff = (event) => {
    const { name, value } = event.target;
    const newTimeOffReport = { ...timeOffReport, [name]: value };
    setTimeOffReport(newTimeOffReport);
    setIsTimeOffFormValid(
      !!newTimeOffReport.name &&
        !!newTimeOffReport.email &&
        !!newTimeOffReport.dateFrom &&
        !!newTimeOffReport.dateTo
    );
  };

  const handleChangeTimeOffDate = (value, name) => {
    const newTimeOffReport = { ...timeOffReport, [name]: value };
    setTimeOffReport(newTimeOffReport);
    setIsTimeOffFormValid(
      !!newTimeOffReport.name &&
        !!newTimeOffReport.email &&
        !!newTimeOffReport.dateFrom &&
        !!newTimeOffReport.dateTo
    );
  };

  const handleSendTimeReport = () => {
    setLoading(true);
    ReportsService.postTimeReport(timeReport)
      .then((result) => handleSuccess(result))
      .catch((error) => handleError(error));
  };

  const handleSendUserSummariesReport = () => {
    setLoading(true);
    ReportsService.postUserSummariesReport(userSummariesReport)
      .then((result) => handleSuccess(result))
      .catch((error) => handleError(error));
  };
  const handleSendProjectsReport = () => {
    setLoading(true);
    ReportsService.postProjectReport(projectsReport)
      .then((result) => handleSuccess(result))
      .catch((error) => handleError(error));
  };
  const handleSendTimeOffReport = () => {
    setLoading(true);
    ReportsService.postTimeOffReport(timeOffReport)
      .then((result) => handleSuccess(result))
      .catch((error) => handleError(error));
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("report_success"), "success"));
    setLoading(false);
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("report_error"), "error"));
    setLoading(false);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h1"> {t("reports")}</Typography>
        </Grid>
        {/* Time */}
        <Grid item xs={12} md={9}>
          <Card>
            <CardHeader
              title={t("time_report.title")}
              titleTypographyProps={{ variant: "h3" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" component={"p"}>
                    {t("time_report.info_1")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component={"p"}>
                    {t("time_report.info_2")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h5"} component={"h5"}>
                    {t("period")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={timeReport.dateFrom}
                    name={"dateFrom"}
                    label={t("from")}
                    required
                    onChange={(value) =>
                      handleChangeTimeDate(value, "dateFrom")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={timeReport.dateTo}
                    name={"dateTo"}
                    label={t("to")}
                    required
                    onChange={(value) => handleChangeTimeDate(value, "dateTo")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h5"} component={"h5"}>
                    {t("send_to")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"text"}
                    value={timeReport.name}
                    name={"name"}
                    onChange={handleChangeTime}
                    label={t("name")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"text"}
                    value={timeReport.email}
                    name={"email"}
                    onChange={handleChangeTime}
                    label={t("email")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={handleSendTimeReport}
                    disabled={!isTimeFormValid}
                  >
                    {t("send_report")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* User summaries  */}
        <Grid item xs={12} md={9}>
          <Card>
            <CardHeader
              title={t("user_summaries_report.title")}
              titleTypographyProps={{ variant: "h3" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" component={"p"}>
                    {t("user_summaries_report.info_1")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component={"p"}>
                    {t("user_summaries_report.info_2")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h5"} component={"h5"}>
                    {t("date")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={timeReport.dateFrom}
                    name={"date"}
                    label={t("date")}
                    required
                    onChange={(value) =>
                      handleChangeUserSummariesDate(value, "date")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h5"} component={"h5"}>
                    {t("send_to")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"text"}
                    value={userSummariesReport.name}
                    name={"name"}
                    onChange={handleChangeUserSummaries}
                    label={t("name")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"text"}
                    value={userSummariesReport.email}
                    name={"email"}
                    onChange={handleChangeUserSummaries}
                    label={t("email")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={handleSendUserSummariesReport}
                    disabled={!isUserSummariesFormValid}
                  >
                    {t("send_report")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Projects*/}
        {organization?.options?.projects && (
          <Grid item xs={12} md={9}>
            <Card>
              <CardHeader
                title={t("projects_report.title")}
                titleTypographyProps={{ variant: "h3" }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" component={"p"}>
                      {t("projects_report.info_1")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" component={"p"}>
                      {t("projects_report.info_2")}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12}> */}
                  {/*   <Typography variant={"h5"} component={"h5"}> */}
                  {/*     {t("period")} */}
                  {/*   </Typography> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  {/*   <TextField */}
                  {/*     type={"date"} */}
                  {/*     value={report.dateFrom} */}
                  {/*     name={"dateFrom"} */}
                  {/*     onChange={handleChangeTime} */}
                  {/*     InputLabelProps={{ shrink: true }} */}
                  {/*     label={t("from")} */}
                  {/*     required */}
                  {/*   /> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  {/*   <TextField */}
                  {/*     type={"date"} */}
                  {/*     value={report.dateTo} */}
                  {/*     name={"dateTo"} */}
                  {/*     onChange={handleChangeTime} */}
                  {/*     InputLabelProps={{ shrink: true }} */}
                  {/*     label={t("to")} */}
                  {/*     required */}
                  {/*   /> */}
                  {/* </Grid> */}
                  <Grid item xs={12}>
                    <Typography variant={"h5"} component={"h5"}>
                      {t("send_to")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type={"text"}
                      value={projectsReport.name}
                      name={"name"}
                      onChange={handleChangeProjects}
                      label={t("name")}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type={"text"}
                      value={projectsReport.email}
                      name={"email"}
                      onChange={handleChangeProjects}
                      label={t("email")}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      component="label"
                      color="primary"
                      onClick={handleSendProjectsReport}
                      disabled={!isProjectsFormValid}
                    >
                      {t("send_report")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* Time Off*/}
        <Grid item xs={12} md={9}>
          <Card>
            <CardHeader
              title={t("time_off_report.title")}
              titleTypographyProps={{ variant: "h3" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" component={"p"}>
                    {t("time_off_report.info_1")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component={"p"}>
                    {t("time_off_report.info_2")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={timeOffReport.dateFrom}
                    name={"dateFrom"}
                    label={t("from")}
                    required
                    onChange={(value) =>
                      handleChangeTimeOffDate(value, "dateFrom")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={timeOffReport.dateTo}
                    name={"dateTo"}
                    label={t("to")}
                    required
                    onChange={(value) =>
                      handleChangeTimeOffDate(value, "dateTo")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h5"} component={"h5"}>
                    {t("send_to")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"text"}
                    value={timeOffReport.name}
                    name={"name"}
                    onChange={handleChangeTimeOff}
                    label={t("name")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={"text"}
                    value={timeOffReport.email}
                    name={"email"}
                    onChange={handleChangeTimeOff}
                    label={t("email")}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={handleSendTimeOffReport}
                    disabled={!isTimeOffFormValid}
                  >
                    {t("send_report")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
