import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";

import { displayAlert } from "../../../../slices/feedbackSlice";
import TimesheetsService from "../../../../services/timesheets.service";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    justifyContent: "space-between",
  },
}));

export default function ReminderModal({ display, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));

  const { t } = useTranslation("users");

  const handleSuccess = () => {
    dispatch(displayAlert(t("reminder_success"), "success"));
    setLoading(false);
    handleClose();
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("reminder_error"), "error"));
    setLoading(false);
  };

  const handleSendReminder = () => {
    setLoading(true);
    TimesheetsService.sendReminderToOrganization(date.format("YYYY-MM-DD"))
      .then((result) => handleSuccess(result))
      .catch((error) => handleError(error));
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      <DialogTitle variant="h4">
        {t("reminder_modal.title")}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1">
              {t("reminder_modal.confirmation")}
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <DatePicker
              value={date}
              firstDayOfWeek={0}
              onChange={(value) => setDate(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className={classes.wrapper}>
          <LoadingButton
            variant="contained"
            component="label"
            color="primary"
            onClick={handleSendReminder}
            loading={loading}
          >
            {t("reminder_modal.confirm_button")}
          </LoadingButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}
