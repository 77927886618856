import { Card, CardContent, Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FiscalYear from "./FiscalYear/FiscalYear";

import NewFiscalYear from "./NewFiscalYear/NewFiscalYear";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

export default function FiscalYears() {
  const [displayAddFiscalYear, setDisplayAddFiscalYear] = useState(false);
  const fiscalYears = useSelector((state) => state.timesheets.fiscalYears);
  const [displayButtonAddFiscalYear, setDisplayButtonAddFiscalYear] =
    useState(false);
  const { t } = useTranslation("myAccount");

  const handleAddFiscalYear = () => {
    setDisplayAddFiscalYear(true);
  };
  const handleCancel = () => {
    setDisplayAddFiscalYear(false);
  };

  useEffect(() => {
    //change 3 to 4 in March 2024
    if (fiscalYears.length < 3) {
      setDisplayButtonAddFiscalYear(true);
    }
  }, [fiscalYears]);

  return (
    <Card>
      <CardHeader
        title={t("fiscal_years.title")}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {fiscalYears.map((fiscalYear) => (
            <FiscalYear
              dateFrom={fiscalYear.date_from}
              dateTo={fiscalYear.date_to}
              status={fiscalYear.status}
              weekFirstDay={fiscalYear.week_first_day}
              key={fiscalYear.date_from}
            />
          ))}
          {displayAddFiscalYear ? (
            <>
              <NewFiscalYear handleCancel={handleCancel} />
            </>
          ) : (
            displayButtonAddFiscalYear && (
              <Grid item xs={12}>
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddFiscalYear}
                  variant={"outlined"}
                  color={"darkPrimary"}
                >
                  {t("fiscal_years.add_fiscal_year")}
                </Button>
              </Grid>
            )
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
