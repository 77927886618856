import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import makeStyles from "@mui/styles/makeStyles";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import SubscribeModal from "./SubscribeModal/SubscribeModal";
import { displayAlert, setLoading } from "../../../slices/feedbackSlice";
import FiscalYears from "./FiscalYears/FiscalYears";
import OrganizationRules from "./OrganizationRules/OrganizationRules";
import ConfigurationAssistantModal from "../../ConfigurationAssistantModal/ConfigurationAssistantModal";
import { getOrganization } from "../../../slices/accountSlice";
import { getFiscalYears } from "../../../slices/timesheetsSlice";
import OrganizationOptions from "./OrganizationOptions/OrganizationOptions";
import PaymentService from "../../../services/payment.service";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
}));

export default function MyAccount() {
  const classes = useStyles();
  const { t } = useTranslation("myAccount");

  const organization = useSelector((state) => state.account.organization);
  const configs = useSelector((state) => state.account.organizationConfigs);
  const fiscalYears = useSelector((state) => state.timesheets.fiscalYears);
  const dataLoading = useSelector((state) => state.feedback.loading);

  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [configurationModalOpen, setConfigurationModalOpen] = useState(false);

  const location = useLocation().pathname;
  const paymentStatus = location.split("/").pop();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      paymentStatus &&
      ["error", "success", "update_success"].includes(paymentStatus)
    ) {
      dispatch(
        displayAlert(t(`payment_status_${paymentStatus}`), paymentStatus)
      );
    }
  }, [t, dispatch, paymentStatus]);

  useEffect(() => {
    if (!organization.name) {
      dispatch(getOrganization());
    }
  }, [organization]);

  useEffect(() => {
    if (!dataLoading && !fiscalYears.length) {
      dispatch(getFiscalYears())
        .unwrap()
        .then((newFiscalYears) => {
          if (!newFiscalYears.fiscalYears.length) {
            setConfigurationModalOpen(true);
          }
        })
        .catch(() => dispatch(setLoading(false)));
    }
  }, [dataLoading]);

  const handleConfigurationModalClose = () => {
    setConfigurationModalOpen(false);
  };

  const handleSubscribeModalClose = () => {
    setSubscribeModalOpen(false);
  };

  const handleSubscribeModalOpen = () => {
    setSubscribeModalOpen(true);
  };

  const handleUpdateAccount = () => {
    setLoading(true);
    PaymentService.createCustomerPortalLink()
      .then((result) => window.open(result.data.url, "_blank").focus())
      .catch((err) => dispatch(displayAlert(t("error"), "error")));
  };

  const subscriptionDisplay =
    organization.is_subscribed || paymentStatus === "success" ? (
      <>
        <Grid item xs={12}>
          <Typography component="p" variant="overline">
            {t(`plans.${organization.subscription?.interval}.name`)}
          </Typography>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"left"}>
          <Button
            variant="outlined"
            component="label"
            color="darkPrimary"
            onClick={handleUpdateAccount}
          >
            {t("update_account")}
          </Button>
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={12}>
          <Typography component="p" variant="overline" color="error">
            {t("unsubscribed")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" variant="body1">
            {t("please_subscribe")}
          </Typography>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"right"}>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            onClick={handleSubscribeModalOpen}
          >
            {t("subscribe")}
          </Button>
        </Grid>
      </>
    );

  return (
    <>
      {configurationModalOpen && (
        <ConfigurationAssistantModal
          display={configurationModalOpen}
          handleClose={handleConfigurationModalClose}
        />
      )}
      <SubscribeModal
        display={subscribeModalOpen}
        handleClose={handleSubscribeModalClose}
      />
      <Grid container spacing={2} className={classes.root}>
        {/* Subscription */}
        <Grid item xs={12} md={9}>
          <Card>
            <CardHeader
              title={t("subscription")}
              titleTypographyProps={{ variant: "h3" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                {subscriptionDisplay}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={9}>
          <FiscalYears />
        </Grid>
        <Grid item xs={12} md={9}>
          <OrganizationOptions organization={organization} />
        </Grid>
        <Grid item xs={12} md={9}>
          <OrganizationRules organization={organization} configs={configs} />
        </Grid>
      </Grid>
    </>
  );
}
