import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialSnackbar from "@mui/material/Snackbar";

import Alert from "@mui/material/Alert";
import { setSnackbarDisplay } from "../../../../slices/feedbackSlice";

const AUTOHIDE_DURATION = 4000;

export default function Snackbar() {
  const dispatch = useDispatch();

  const { snackbarDisplayed, snackbarContent } = useSelector(
    (state) => state.feedback
  );

  const handleClose = () => {
    dispatch(setSnackbarDisplay(false));
  };

  return (
    <MaterialSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={snackbarDisplayed}
      autoHideDuration={AUTOHIDE_DURATION}
      onClose={handleClose}
      aria-describedby="client-snackbar"
    >
      <Alert onClose={handleClose} severity={snackbarContent.severity}>
        {snackbarContent.message}
      </Alert>
    </MaterialSnackbar>
  );
}
