import React from "react";

import Grid from "@mui/material/Grid";

import TimesheetSummary from "./TimesheetSummary/TimesheetSummary";
import UserSummary from "../../Users/UserSummary/UserSummary";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

export default function Timesheets({ timesheets, user }) {
  const contractStartDate = user
    ? new Date(user.first_day_of_first_week)
    : new Date("2020-01-01");

  const userSummaries = useSelector((state) => state.timesheets.userSummaries);

  return (
    <Grid item xs={12}>
      <Grid container rowSpacing={6} columnSpacing={6}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {user && user.id && userSummaries && userSummaries[user.id] && (
                <>
                  <Typography
                    color={"primary.darkest"}
                    variant="h3"
                    component="p"
                    display={"flex"}
                    alignItems={"flex-end"}
                    s
                    marginBottom={"1rem"}
                  >
                    {user.first_name} {user.last_name}
                  </Typography>
                  <UserSummary
                    user={user}
                    userSummary={userSummaries[user.id]}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {timesheets
          .filter(
            (timesheet) => new Date(timesheet.date_from) >= contractStartDate
          )
          .map((timesheet) => (
            <TimesheetSummary
              key={timesheet.id}
              timesheet={timesheet}
              userSummary={userSummaries[user?.id]}
              user={user || {}}
            />
          ))}
      </Grid>
    </Grid>
  );
}
