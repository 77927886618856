import { DialogContent, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: "10px",
  },
}));

export default function TimeOffConfig({
  handlePrevious,
  handleNext,
  handleChangeConfig,
  config,
}) {
  const classes = useStyles();
  const { t } = useTranslation("myAccount");

  const handleClickNext = () => {
    handleNext();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newTimeOffConfig = {
      ...config.timeOff,
      [name]: value,
    };
    handleChangeConfig("timeOff", newTimeOffConfig);
  };

  const timeUnits = [
    <MenuItem key="hour" value="hour">
      {t("rules.hour")}
    </MenuItem>,
    <MenuItem key="day" value="day" id="day">
      {t("rules.day")}
    </MenuItem>,
    <MenuItem key="week" value="week">
      {t("rules.week")}
    </MenuItem>,
    <MenuItem key="month" value="month">
      {t("rules.month")}
    </MenuItem>,
    <MenuItem key="year" value="year">
      {t("rules.year")}
    </MenuItem>,
  ];

  const timeUnitsPlural = [
    <MenuItem key="hour" value="hour">
      {t("rules.hours")}
    </MenuItem>,
    <MenuItem key="day" value="day" id="day">
      {t("rules.days")}
    </MenuItem>,
    <MenuItem key="week" value="week">
      {t("rules.weeks")}
    </MenuItem>,
    <MenuItem key="month" value="month">
      {t("rules.months")}
    </MenuItem>,
    <MenuItem key="year" value="year">
      {t("rules.years")}
    </MenuItem>,
  ];

  const bankMethod = [
    <MenuItem
      key="fiscal_year_yearly"
      value="fiscal_year_yearly"
      id="fiscal_year_yearly"
    >
      {t("rules.methods.fiscal_year_start")}
    </MenuItem>,
    <MenuItem
      key="contract_anniversary_yearly"
      value="contract_anniversary_yearly"
      id="contract_anniversary_yearly"
    >
      {t("rules.methods.contract_start")}
    </MenuItem>,
    <MenuItem key="monthly" value="monthly" id="monthly">
      {t("rules.methods.monthly")}
    </MenuItem>,
    <MenuItem key="progressive" value="progressive" id="progressive">
      {t("rules.methods.progressive")}
    </MenuItem>,
  ];
  const bankReset = [
    <MenuItem
      key="fiscal_year_yearly"
      value="fiscal_year_yearly"
      id="fiscal_year_yearly"
    >
      {t("rules.methods.fiscal_year_start")}
    </MenuItem>,
    <MenuItem
      key="contract_anniversary_yearly"
      value="contract_anniversary_yearly"
      id="contract_anniversary_yearly"
    >
      {t("rules.methods.contract_anniversary")}
    </MenuItem>,
    <MenuItem key="monthly" value="monthly" id="monthly">
      {t("rules.methods.monthly")}
    </MenuItem>,
    <MenuItem key="never" value="never" id="never">
      {t("rules.methods.never")}
    </MenuItem>,
  ];

  return (
    <>
      <DialogContent>
        <Grid container spacing={0}>
          <InfoBox level={"info"}>
            {t("config_assistant.global_values_info")}
          </InfoBox>
          <Grid item xs={12} marginTop={2}>
            <Typography type="h3" variant="h3" className={classes.intro}>
              {t("config_assistant.time_off.vacation")}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.time_off.vacation_info")}
            </Typography>
            <form onChange={handleChange}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    type={"number"}
                    value={config.timeOff.vacationValue}
                    InputLabelProps={{ shrink: true }}
                    name={"vacationValue"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={config.timeOff.unit}
                    onChange={handleChange}
                    fullWidth
                    name={"unit"}
                  >
                    {timeUnitsPlural}
                  </Select>
                </Grid>
                <Grid item xs={1} justifyContent="center" display="flex">
                  <Typography
                    component="p"
                    variant="body1"
                    align="center"
                    margin="auto"
                  >
                    {t("rules.by")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={config.timeOff.period}
                    onChange={handleChange}
                    fullWidth
                    name={"period"}
                  >
                    {timeUnits}
                  </Select>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Grid container spacing={0} marginTop={2}>
          <Grid item xs={12}>
            <Typography type="h3" variant="h3" className={classes.intro}>
              {t("config_assistant.time_off.sickness")}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.time_off.sickness_info")}
            </Typography>
            <form onChange={handleChange}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    type={"number"}
                    value={config.timeOff.sickValue}
                    InputLabelProps={{ shrink: true }}
                    name={"sickValue"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={config.timeOff.unit}
                    onChange={handleChange}
                    fullWidth
                    name={"unit"}
                    disabled
                  >
                    {timeUnitsPlural}
                  </Select>
                </Grid>
                <Grid item xs={1} justifyContent="center" display="flex">
                  <Typography
                    component="p"
                    variant="body1"
                    align="center"
                    margin="auto"
                  >
                    {t("rules.by")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={config.timeOff.period}
                    onChange={handleChange}
                    fullWidth
                    name={"period"}
                    disabled
                  >
                    {timeUnits}
                  </Select>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <form onChange={handleChange}>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} marginTop={4}>
              <Typography component="h3" variant="h3">
                {t("rules.methods.title")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InfoBox level={"info"}>
                {t("rules.methods.info_1")}
                <p>
                  {t("rules.methods.info_2")}

                  {/*TODO : Fix link*/}
                  <a
                    href={
                      "https://feuilledetemps.ca/docs/tutorial-admin/methode-calcul-banques"
                    }
                    target="_blank"
                    rel="noopener"
                  >
                    {t("rules.methods.info_link")}
                  </a>
                </p>
              </InfoBox>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h4" variant="h4">
                {t("rules.vacation")}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <Typography
                component="body1"
                variant="p"
                align="left"
                margin="auto 0"
                width={"30%"}
              >
                {t("rules.methods.hours_gained")}
              </Typography>
              <Select
                value={config.timeOff.vacationDaysMethod}
                onChange={handleChange}
                name={"vacationDaysMethod"}
              >
                {bankMethod}
              </Select>
            </Grid>
            <Grid item xs={12} display="flex">
              <Typography
                component="body1"
                variant="p"
                align="left"
                margin="auto 0"
                width={"30%"}
              >
                {t("rules.methods.hours_reset")}
              </Typography>
              <Select
                value={config.timeOff.vacationDaysReset}
                onChange={handleChange}
                name={"vacationDaysReset"}
              >
                {bankReset}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h4" variant="h4">
                {t("rules.sickness")}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <Typography
                component="body1"
                variant="p"
                align="left"
                margin="auto 0"
                width={"30%"}
              >
                {t("rules.methods.hours_gained")}
              </Typography>
              <Select
                value={config.timeOff.sickDaysMethod}
                onChange={handleChange}
                name={"sickDaysMethod"}
              >
                {bankMethod}
              </Select>
            </Grid>
            <Grid item xs={12} display="flex">
              <Typography
                component="body1"
                variant="p"
                align="left"
                margin="auto 0"
                width={"30%"}
              >
                {t("rules.methods.hours_reset")}
              </Typography>
              <Select
                value={config.timeOff.sickDaysReset}
                onChange={handleChange}
                name={"sickDaysReset"}
              >
                {bankReset}
              </Select>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          component="label"
          color="darkSecondary"
          onClick={handlePrevious}
        >
          {t("config_assistant.previous")}
        </Button>
        <Button
          variant="contained"
          component="label"
          color="primary"
          onClick={handleClickNext}
        >
          {t("config_assistant.next")}
        </Button>
      </DialogActions>
    </>
  );
}
