import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import { deleteTeam } from "../../../../slices/accountSlice";
import { LoadingButton } from "@mui/lab";
import { displayAlert } from "../../../../slices/feedbackSlice";
import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  teamName: {
    fontWeight: 600,
  },
}));

export default function DeleteTeamModal({ display, handleClose, team }) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation("teams");
  const dispatch = useDispatch();

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteTeam(team.id))
      .unwrap()
      .then(() => dispatch(displayAlert(t("team_delete_success"), "success")))
      .catch((err) => dispatch(displayAlert(t("error"), "error")));
    setLoading(false);
    handleClose();
  };

  const modalContent = (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {t("delete_team_modal.title")}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InfoBox level={"error"}>
              {t("are_you_sure_to_delete")}{" "}
              <span className={classes.teamName}>{team.name}</span>
              {"? "}
            </InfoBox>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          loading={loading}
        >
          {t("delete_team_modal.delete_button")}
          <DeleteForeverIcon />
        </LoadingButton>
      </DialogActions>
    </>
  );

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      {modalContent}
    </Dialog>
  );
}
