import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const LOGIN_URL = `${API_URL}/accounts/login/`;
const ADMIN_SIGNUP_URL = `${API_URL}/accounts/admin-signup/`;

const signup = (firstName, lastName, email, password, organization) =>
  axios.post(ADMIN_SIGNUP_URL, {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    organization_name: organization,
  });

const login = (email, password) =>
  axios.post(LOGIN_URL, {
    email,
    password,
  });

const logout = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
};

const resetPassword = (email) =>
  axios.post(`${API_URL}/accounts/password/reset/`, {
    email,
  });

const resetPasswordVerify = (code) =>
  axios.get(`${API_URL}/accounts/password/reset/verify/?code=${code}`);

const resetPasswordVerified = (code, newPassword) =>
  axios.post(`${API_URL}/accounts/password/reset/verified/`, {
    code,
    password: newPassword,
  });

const authService = {
  signup,
  login,
  logout,
  resetPassword,
  resetPasswordVerify,
  resetPasswordVerified,
};

export default authService;
