export default function authHeader() {
  //TODO : remove user presence in local storage at the end of March
  const user = JSON.parse(localStorage.getItem("user"));
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];

  if (token) {
    return { Authorization: `token ${token}` };
  } else if (user && user.token) {
    document.cookie = `token=${user.token}; SameSite=None; Secure; SameSite=Strict; Max-Age=31556952`;
    return { Authorization: `token ${user.token}` };
  }

  return {};
}
