import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import CreateProjectModal from "./CreateProjectModal/CreateProjectModal";
import Paper from "@mui/material/Paper";
import { getProjects, getProjectSummary } from "../../../slices/projectsSlice";
import { getUsers } from "../../../slices/accountSlice";
import UpdateProjectModal from "./UpdateProjectModal/UpdateProjectModal";
import DeleteProjectModal from "./DeleteProjectModal/DeleteProjectModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { setLoading } from "../../../slices/feedbackSlice";
import ProjectSummaryModal from "./ProjectSummaryModal/ProjectSummaryModal";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
  nameCell: {
    maxWidth: "10rem",
    fontWeight: "500",
  },
  statusCell: {
    width: "6rem",
  },
  timeCell: {
    width: "8rem",
  },
  timeValueCell: {
    textAlign: "center",
  },
  usersCell: {
    maxWidth: "20rem",
  },
  editCell: {
    textAlign: "center",
    width: "6rem",
  },
  name: {
    marginRight: "1rem",
    display: "inline-block",
  },
  icon: {
    cursor: "pointer",
  },
}));

export default function Projects() {
  const classes = useStyles();
  const { t } = useTranslation("projects");
  const dispatch = useDispatch();

  const users = useSelector((state) => state.account.users);
  const projects = useSelector((state) => state.projects.projects);
  const projectSummaries = useSelector(
    (state) => state.projects.projectSummaries
  );

  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const [updateProjectModalOpen, setUpdateProjectModalOpen] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [toDeleteProject, setToDeleteProject] = useState({});
  const [projectSummaryModalOpen, setProjectSummaryModalOpen] = useState(false);
  const [selectedProjectSummary, setSelectedProjectSummary] = useState({});

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getProjects())
      .unwrap()
      .then((response) =>
        response.projects.forEach((project) => {
          dispatch(getProjectSummary(project.id))
            .unwrap()
            .then(() => dispatch(setLoading(false)));
        })
      );
  }, []);

  const handleAddProjectModalClose = () => {
    setCreateProjectModalOpen(false);
  };
  const handleAddProjectModalOpen = () => {
    setCreateProjectModalOpen(true);
  };
  const handleUpdateProjectModalClose = () => {
    setUpdateProjectModalOpen(false);
  };

  const handleUpdateProjectModalOpen = (project) => {
    setEditedProject(project);
    setUpdateProjectModalOpen(true);
  };
  const handleDeleteProjectModalClose = () => {
    setDeleteProjectModalOpen(false);
  };

  const handleDeleteProjectModalOpen = (project) => {
    setToDeleteProject(project);
    setDeleteProjectModalOpen(true);
  };

  const handleProjectSummaryModalClose = () => {
    setProjectSummaryModalOpen(false);
  };

  const handleProjectSummaryModalOpen = (projectId) => {
    setSelectedProjectSummary(projectSummaries[projectId]);
    setProjectSummaryModalOpen(true);
  };

  const displayUserLabelsFromIds = (userIds) => {
    const displayedUsers = users.filter((user) => userIds.includes(user.id));
    return displayedUsers.map((user) => (
      <span key={user.id} className={classes.name}>
        {user.full_name}
      </span>
    ));
  };

  const displayProjectTime = (projectId) => {
    const projectSummary = projectSummaries[projectId];
    return projectSummary?.summary.total_hours;
  };

  return (
    <>
      {createProjectModalOpen && (
        <CreateProjectModal
          display={createProjectModalOpen}
          handleClose={handleAddProjectModalClose}
          users={users}
        />
      )}
      {updateProjectModalOpen && (
        <UpdateProjectModal
          display={updateProjectModalOpen}
          handleClose={handleUpdateProjectModalClose}
          users={users}
          project={editedProject}
        />
      )}
      {deleteProjectModalOpen && (
        <DeleteProjectModal
          display={deleteProjectModalOpen}
          handleClose={handleDeleteProjectModalClose}
          project={toDeleteProject}
        />
      )}
      {projectSummaryModalOpen && (
        <ProjectSummaryModal
          display={projectSummaryModalOpen}
          handleClose={handleProjectSummaryModalClose}
          projectSummary={selectedProjectSummary}
        />
      )}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} marginBottom={2}>
          <Typography component="h2" variant="h2">
            {t("projects")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            onClick={handleAddProjectModalOpen}
          >
            {t("create_project")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label={t("projects")}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.nameCell}>
                    {t("name")}
                  </TableCell>
                  <TableCell className={classes.statusCell}>
                    {t("status")}
                  </TableCell>
                  <TableCell className={classes.timeCell}>
                    {t("total_hours")}
                  </TableCell>
                  <TableCell className={classes.usersCell}>
                    {t("users")}
                  </TableCell>
                  <TableCell className={classes.editCell}>
                    {t("edit")}
                  </TableCell>
                  <TableCell className={classes.editCell}>
                    {t("delete")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project) => {
                  return (
                    <TableRow hover key={project.id}>
                      <TableCell className={classes.nameCell}>
                        {project.name}
                      </TableCell>
                      <TableCell className={classes.statusCell}>
                        {t(project.status)}
                      </TableCell>
                      <TableCell
                        className={classes.timeValueCell}
                        onClick={() =>
                          handleProjectSummaryModalOpen(project.id)
                        }
                      >
                        {displayProjectTime(project.id)} h
                      </TableCell>
                      <TableCell className={classes.usersCell}>
                        {displayUserLabelsFromIds(project.users)}
                      </TableCell>
                      <TableCell className={classes.editCell}>
                        <EditIcon
                          className={classes.icon}
                          onClick={() => handleUpdateProjectModalOpen(project)}
                        />
                      </TableCell>
                      <TableCell className={classes.editCell}>
                        <DeleteForeverIcon
                          onClick={() => handleDeleteProjectModalOpen(project)}
                          className={classes.icon}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
