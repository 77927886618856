import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { round } from "../../../../utils/helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../Layout/Spinner/Spinner";

export default function UserSummary({ user, userSummary = {} }) {
  const { t } = useTranslation("users");
  const hasFamilyHours = user.timesheet_columns?.has_family_hours;
  const familyHoursCustomName =
    user.timesheet_columns?.family_hours_custom_name;

  const formatOvertime = () => {
    const configs = user.organization_configs;
    let currentConfig = configs.find((config) => config.is_current);
    currentConfig = currentConfig || configs[0];
    const maxCumulatedOvertimeHours =
      currentConfig.max_cumulated_overtime_hours;
    if (
      maxCumulatedOvertimeHours !== null &&
      maxCumulatedOvertimeHours < userSummary.overtime_hours
    ) {
      return (
        <Typography color={"text.secondary"} style={{ fontWeight: 600 }}>
          {round(userSummary.overtime_hours || 0)} {t("hours")} (
          {round(userSummary.overtime_days || 0)} {t("days")})
        </Typography>
      );
    }
    return (
      <Typography>
        {round(userSummary.overtime_hours || 0)} {t("hours")} (
        {round(userSummary.overtime_days || 0)} {t("days")})
      </Typography>
    );
  };

  const formatMaxVacation = () => {
    const configs = user.organization_configs;
    let currentConfig = configs.find((config) => config.is_current);
    currentConfig = currentConfig || configs[0];
    const maxCumulatedVacationDays = currentConfig.max_cumulated_vacation_days;
    if (
      maxCumulatedVacationDays !== null &&
      maxCumulatedVacationDays < userSummary.vacation_days
    ) {
      return (
        <Typography color={"text.secondary"} style={{ fontWeight: 600 }}>
          {round(userSummary.vacation_hours || 0)} {t("hours")} (
          {round(userSummary.vacation_days || 0)} {t("days")})
        </Typography>
      );
    }
    return (
      <Typography>
        {round(userSummary.vacation_hours || 0)} {t("hours")} (
        {round(userSummary.vacation_days || 0)} {t("days")})
      </Typography>
    );
  };

  const formatMaxSick = () => {
    const configs = user.organization_configs;
    let currentConfig = configs.find((config) => config.is_current);
    currentConfig = currentConfig || configs[0];
    const maxCumulatedSickDays = currentConfig.max_cumulated_sick_days;
    if (
      maxCumulatedSickDays !== null &&
      maxCumulatedSickDays < userSummary.sick_days
    ) {
      return (
        <Typography color={"text.secondary"} style={{ fontWeight: 600 }}>
          {round(userSummary.sick_hours || 0)} {t("hours")} (
          {round(userSummary.sick_days || 0)} {t("days")})
        </Typography>
      );
    }
    return (
      <Typography>
        {round(userSummary.sick_hours || 0)} {t("hours")} (
        {round(userSummary.sick_days || 0)} {t("days")})
      </Typography>
    );
  };

  return (
    <Grid
      item
      xs={12}
      md={12}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h5">{t("sick")} :</Typography>
        </Grid>
        <Grid item xs={5}>
          {userSummary.user_id ? formatMaxSick() : "..."}
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h5">{t("vacation")} :</Typography>
        </Grid>
        <Grid item xs={5}>
          {userSummary.user_id ? formatMaxVacation() : "..."}
        </Grid>
        {userSummary.has_compensatory_days ? (
          <>
            <Grid item xs={7}>
              <Typography variant="h5">{t("compensatory")} :</Typography>
            </Grid>
            <Grid item xs={5}>
              {userSummary.user_id ? (
                <Typography>
                  {round(userSummary.compensatory_hours || 0)} {t("hours")} (
                  {round(userSummary.compensatory_days || 0)} {t("days")})
                </Typography>
              ) : (
                "..."
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={7}>
              <Typography variant="h5">{t("overtime")} :</Typography>
            </Grid>
            <Grid item xs={5}>
              {userSummary.user_id ? formatOvertime() : "..."}
            </Grid>
          </>
        )}
        {hasFamilyHours && (
          <>
            <Grid item xs={7}>
              <Typography variant="h5">
                {familyHoursCustomName || t("family_hours")} :
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {round(userSummary.family_hours || 0)} {t("hours")} (
              {round(userSummary.family_days || 0)} {t("days")})
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
