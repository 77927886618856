import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    marginTop: "300px",
    height: "50%",
    width: "80%",
  },
}));

export default function ErrorFallbackGlobal() {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item sm={12}>
        <InfoBox level={"error"}>
          <Typography variant={"h2"}>{t("global_unknown_error")}</Typography>
          <Typography variant={"body1"}>{t("sorry")}</Typography>
          <Typography variant={"body1"}>
            {t("refresh_page_or_contact")}
          </Typography>
        </InfoBox>
      </Grid>
    </Grid>
  );
}
