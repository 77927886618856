import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Drawer, Button, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";

import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import NavigationItems from "../NavigationItems/NavigationItems";
import { logout } from "../../../slices/authSlice";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { help_center_urls } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  signOutButton: {
    padding: "10px 8px",
    justifyContent: "space-between",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: "auto",
    color: theme.palette.text.error,
  },
  helpButton: {
    padding: "8px 8px",
    justifyContent: "space-between",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    // marginBottom: "auto",
    color: theme.palette.text.primary,
  },
}));

const SideBar = ({ open, variant, onClose }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("navbar");
  const locale = i18n.language;

  const dispatch = useDispatch();
  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.clear();
    dispatch(logout());
  };

  const handleClickHelp = () => {
    window.open(help_center_urls[locale].intro, "_blank").focus();
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root)}>
        {/* <Profile /> */}
        <Button className={classes.helpButton} onClick={handleClickHelp}>
          {t("help")}
          <HelpOutlineIcon />
        </Button>
        <Divider className={classes.divider} />
        <NavigationItems />
        <Button
          className={classes.signOutButton}
          onClick={handleLogout}
          color={"inherit"}
        >
          {t("logout")}
          <LogoutIcon />
        </Button>
      </div>
    </Drawer>
  );
};

SideBar.defaultProps = {};
SideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SideBar;
