import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  projectName: {
    fontWeight: 600,
  },
}));

export default function ProjectSummaryModal({
  display,
  handleClose,
  projectSummary,
}) {
  const classes = useStyles();
  const { t } = useTranslation("projects");

  const users = useSelector((state) => state.account.users);

  const modalContent = (
    <>
      <DialogTitle className={classes.root} disableTypography>
        <Typography type="h3" variant="h3">
          {projectSummary.name}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography type="h4" variant="h4">
              {t("total_hours")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="p" variant="body1">
              {projectSummary.summary.total_hours} h
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="h4" variant="h4">
              {t("detail_per_user")}
            </Typography>
          </Grid>
          {[...projectSummary.summary.total_hours_per_user]
            .sort((a, b) => a.total_hours < b.total_hours)
            .map((hoursPerUser) => {
              const user = users.find(
                (user) => user.id === hoursPerUser.user_id
              );
              const userFullName = user?.full_name || t("archived_user");
              return (
                <Grid item xs={12} key={hoursPerUser.user_id}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography type="h4" variant="h4">
                            {userFullName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} key={hoursPerUser.user_id}>
                          <Typography type="p" variant="body1">
                            {hoursPerUser.total_hours} h
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
    </>
  );

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      {modalContent}
    </Dialog>
  );
}
