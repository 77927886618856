import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import { displayAlert } from "../../../../slices/feedbackSlice";
import { LoadingButton } from "@mui/lab";
import { round } from "../../../../utils/helpers";
import TextField from "@mui/material/TextField";
import { inviteUser } from "../../../../slices/accountSlice";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
}));

export default function InviteUserModal({ display, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fields, setFields] = useState({});
  const [displaySpecificParams, setDisplaySpecificParams] = useState(false);
  const organizationConfigs = useSelector(
    (state) => state.account.organizationConfigs
  );
  const [currentOrgConfig, setCurrentOrgConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [workedDays, setWorkedDays] = useState([]);

  const { t } = useTranslation(["users", "common"]);

  const daysOfWeek = [
    { id: 0, label: t("Monday", { ns: "common" }) },
    { id: 1, label: t("Tuesday", { ns: "common" }) },
    { id: 2, label: t("Wednesday", { ns: "common" }) },
    { id: 3, label: t("Thursday", { ns: "common" }) },
    { id: 4, label: t("Friday", { ns: "common" }) },
    { id: 5, label: t("Saturday", { ns: "common" }) },
    { id: 6, label: t("Sunday", { ns: "common" }) },
  ];

  const formatAutocompleteDaysList = (dayIds) => {
    return dayIds.map((id) =>
      daysOfWeek.find((dayOfWeek) => dayOfWeek.id === id)
    );
  };

  useEffect(() => {
    const orderedWorkedDays = [...(currentOrgConfig.worked_days || [])];
    orderedWorkedDays.sort((a, b) => a - b);
    const formattedWorkedDays = formatAutocompleteDaysList(orderedWorkedDays);
    setWorkedDays(formattedWorkedDays);
  }, [currentOrgConfig, displaySpecificParams]);

  useEffect(() => {
    let config = organizationConfigs.find((config) => config.is_current);
    config = config || organizationConfigs[0];
    setCurrentOrgConfig(config);
    setFields({
      unit: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "unit",
        label: t("time_unit"),
        id: "unit",
        value: config.vacation_time_per_period.unit || "",
      },
      period: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "period",
        label: t("time_period"),
        id: "period",
        value: config.vacation_time_per_period.period || "",
      },
      vacationValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "vacationValue",
        label: t(`singular.${config.vacation_time_per_period.unit}`),
        name: "vacationValue",
        value: config.vacation_time_per_period?.time
          ? round(config.vacation_time_per_period.time)
          : "",
        InputLabelProps: { shrink: true },
      },
      sickValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "sickValue",
        label: t(`singular.${config.sick_time_per_period.unit}`),
        name: "sickValue",
        value: config.sick_time_per_period?.time
          ? round(config.sick_time_per_period.time)
          : "",
        InputLabelProps: { shrink: true },
      },
      hoursPerWeek: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "hoursPerWeek",
        label: t("plural.hour"),
        name: "hoursPerWeek",
        value: config.worked_hours_per_week || "",
        InputProps: {
          inputProps: {
            max: 24 * 7,
            min: 0,
          },
        },
        InputLabelProps: { shrink: true },
      },

      email: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "email",
        label: t("email"),
        name: "email",
        autoComplete: "email",
        value: "",
      },
      firstName: {
        autoComplete: "firstName",
        name: "firstName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "firstName",
        label: t("first_name"),
        value: "",
      },
      lastName: {
        autoComplete: "lastName",
        name: "lastName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "lastName",
        label: t("last_name"),
        value: "",
      },
      contractStartDate: {
        name: "contractStartDate",
        type: "date",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "contractStartDate",
        label: t("date"),
        value: "",
        InputLabelProps: { shrink: true },
      },
      reportedSick: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        value: 0,
        id: "reportedSick",
        label: t("plural.hour"),
        name: "reportedSick",
        InputLabelProps: { shrink: true },
      },
      reportedVacation: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        value: 0,
        id: "reportedVacation",
        label: t("plural.hour"),
        name: "reportedVacation",
        InputLabelProps: { shrink: true },
      },
      reportedOvertime: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        value: 0,
        id: "reportedOvertime",
        label: t("plural.hour"),
        name: "reportedOvertime",
        InputLabelProps: { shrink: true },
      },
    });
  }, [t, organizationConfigs]);

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("invite_success"), "success"));
    setLoading(false);
    handleClose();
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("error"), "error"));
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    const userData = Object.values(fields).reduce((acc, elem) => {
      acc[elem.id] = elem.value;
      return acc;
    }, {});

    userData.workedDays = workedDays.map((workedDay) => workedDay.id);
    userData.daysPerWeek = workedDays.length;
    userData.isSpecific = displaySpecificParams;

    if (!displaySpecificParams) {
      // If the specific params are not displayed,
      // we set these values to null top prevent from overriding default values
      userData.sickValue = null;
      userData.vacationValue = null;
      userData.daysPerWeek = null;
      userData.hoursPerWeek = null;
    }
    dispatch(inviteUser(userData))
      .unwrap()
      .then(() => handleSuccess())
      .catch((err) => handleError(err));
  };
  const handleDefineSpecificRules = () =>
    setDisplaySpecificParams(!displaySpecificParams);

  const handleChangeWorkedDays = (event, selectedWorkedDays) => {
    setWorkedDays(selectedWorkedDays);
  };

  const userSpecificParams = () => {
    if (displaySpecificParams) {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="body1">{t("specific_rules")}</Typography>
          </Grid>
          {/* Vacancies */}
          <Grid item xs={12}>
            <Typography component="h4" variant="h4">
              {t("vacation")}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField {...fields.vacationValue} />
          </Grid>

          <Grid item xs={4} display="flex">
            <Typography
              component="p"
              variant="body1"
              align="center"
              margin="auto"
            >
              {t(`plural.${fields.unit.value}`)} {t("by")}{" "}
              {t(`singular.${fields.period.value}`)}
            </Typography>
          </Grid>
          {/* Sickness */}
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" marginTop={"1rem"}>
              {t("sick")}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField {...fields.sickValue} />
          </Grid>
          <Grid item xs={4} display="flex">
            <Typography
              component="p"
              variant="body1"
              align="center"
              margin="auto"
            >
              {t(`plural.${fields.unit.value}`)} {t("by")}{" "}
              {t(`singular.${fields.period.value}`)}
            </Typography>
          </Grid>
          {/* Working time */}
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" marginTop={"1rem"}>
              {t("working_time")}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField {...fields.hoursPerWeek} />
          </Grid>
          <Grid item xs={4} justifyContent="center" display="flex">
            <Typography
              component="p"
              variant="body1"
              align="center"
              margin="auto"
            >
              {t("plural.hour")} {t("by")} {t("singular.week")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={daysOfWeek}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              value={workedDays}
              onChange={handleChangeWorkedDays}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("worked_days", { ns: "common" })}
                />
              )}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="body1">{t("global_rules")}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("sick")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {round(organizationConfigs[0]?.sick_time_per_period.time)}{" "}
            {t(`plural.${organizationConfigs[0]?.sick_time_per_period.unit}`)}{" "}
            {t("by")}{" "}
            {t(
              `singular.${organizationConfigs[0]?.sick_time_per_period.period}`
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("vacation")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {round(organizationConfigs[0]?.vacation_time_per_period.time)}{" "}
            {t(
              `plural.${organizationConfigs[0]?.vacation_time_per_period.unit}`
            )}{" "}
            {t("by")}{" "}
            {t(
              `singular.${organizationConfigs[0]?.vacation_time_per_period.period}`
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("working_time")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {organizationConfigs[0]?.worked_hours_per_week} {t(`plural.hour`)}{" "}
            {t("by")} {t(`singular.week`)} {t("in")} {workedDays.length}{" "}
            {t(`plural.day`)}
          </Grid>
          <Grid item xs={4}>
            <Typography component="h5" variant="h5">
              {t("worked_days", { ns: "common" })}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {workedDays.map((workedDay) => (
              <Typography key={workedDay.id}>{workedDay.label}</Typography>
            ))}
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      <DialogTitle variant="h3">
        {t("invite_user")}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onChange={handleChange}>
          <Grid container spacing={3} marginTop={"1px"}>
            <Grid item xs={12} md={6}>
              <TextField {...fields.firstName} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField {...fields.lastName} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.email} />
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <Typography
                component="label"
                htmlFor={"contractStartDate"}
                variant="h5"
              >
                {t("contract_start_date")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField {...fields.contractStartDate} />
            </Grid>
            <Grid item xs={12}>
              <Typography component="h3" variant="h3" marginTop={"1rem"}>
                {t("reported_data")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="label"
                    htmlFor={"reportedSick"}
                    variant="h5"
                  >
                    {t("sick")}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField {...fields.reportedSick} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="label"
                    htmlFor={"reportedVacation"}
                    variant="h5"
                  >
                    {t("vacation")}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField {...fields.reportedVacation} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="label"
                    htmlFor={"reportedOvertime"}
                    variant="h5"
                  >
                    {t("overtime")}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField {...fields.reportedOvertime} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h3" variant="h3" marginTop={"1rem"}>
                {t("rules")}
              </Typography>
            </Grid>

            <Grid item sm={12}>
              {userSpecificParams()}
            </Grid>
            <Grid item sm={12} marginTop={"1rem"}>
              <Button
                variant="outlined"
                component="label"
                color="darkSecondary"
                onClick={handleDefineSpecificRules}
              >
                {displaySpecificParams
                  ? t("apply_team_rules")
                  : t("define_specific_rules")}
              </Button>
            </Grid>
          </Grid>
          <div className={classes.actions}>
            <Button
              variant="contained"
              component="label"
              color="secondary"
              onClick={handleClose}
              sx={{ height: "100%" }}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              component="label"
              color="primary"
              onClick={handleSave}
              sx={{ height: "100%" }}
            >
              {t("invite")}
            </LoadingButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
