import React from "react";
import { useSelector } from "react-redux";
import { List } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavigationItem from "./NavigationItem/NavigationItem";
import routes from "../../../config/routes";
import { help_center_urls } from "../../../config/config";

const NavigationItems = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state) => state.account.isAdmin);
  const isTeamAdmin = useSelector((state) => state.account.isTeamAdmin);
  const organizationOptions = useSelector(
    (state) => state.account.organizationOptions
  );
  const organizationPlanType = useSelector(
    (state) => state.account.organizationPlanType
  );
  const protectedRoutes = isLoggedIn ? routes.protected : [];
  const allRoutes = [...protectedRoutes, ...routes.public];
  const { t } = useTranslation(["navbar"]);

  return (
    <List>
      {allRoutes
        .filter((route) => route.inNav)
        .filter((route) => (isAdmin ? true : !route.admin))
        .filter((route) => (isTeamAdmin ? true : !route.teamAdmin))
        .filter((route) =>
          route.optionName
            ? organizationOptions.includes(route.optionName)
            : true
        )
        .filter((route) =>
          route.planTypes
            ? route.planTypes.includes(organizationPlanType)
            : true
        )
        .map((route) => (
          <NavigationItem key={route.path} link={route.path}>
            {t(`${route.name}`)}
          </NavigationItem>
        ))}
    </List>
  );
};

export default NavigationItems;
