import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";

import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import UserConfig from "./UserConfig/UserConfig";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
}));

export default function UserRulesModal({
  display,
  handleClose,
  user,
  configs,
  orgConfigs,
  refreshUser,
}) {
  const classes = useStyles();
  const [newConfig, setNewConfig] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(false);
  const [currentOrgConfig, setCurrentOrgConfig] = useState(false);

  const { t } = useTranslation("users");

  useEffect(() => {
    const currentConfigs = configs.filter((config) => config.is_current);
    const currentOrgConfigs = orgConfigs.filter((config) => config.is_current);
    setCurrentConfig(currentConfigs[0] || configs[0]);
    setCurrentOrgConfig(currentOrgConfigs[0] || orgConfigs[0]);
  }, [configs, orgConfigs]);

  const toggleNewConfig = () => {
    setNewConfig(!newConfig);
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      <DialogTitle variant="h3">
        {t("update_user_rules")}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoBox level={"info"}>
              {t("user_rules_info")}
              <br />
              <br />
              <a
                href={
                  "https://feuilledetemps.ca/docs/tutorial-admin/changement-regles-employe"
                }
                target="_blank"
                rel="noopener"
              >
                {t("user_rules_documentation")}
              </a>
            </InfoBox>
            {/* <Typography variant="body1">{t("specific_rules")}</Typography> */}
          </Grid>
          <Grid item xs={12}>
            {configs.map((config) => (
              <UserConfig
                config={config}
                orgConfig={currentOrgConfig}
                key={config.id}
                userId={user.id}
                refreshUser={refreshUser}
              />
            ))}
            {newConfig && (
              <UserConfig
                config={currentConfig}
                orgConfig={currentOrgConfig}
                key={"new"}
                newConfig={true}
                userId={user.id}
                refreshUser={refreshUser}
                handleClose={toggleNewConfig}
              />
            )}
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"right"}>
            <Button
              startIcon={newConfig ? "" : <AddIcon />}
              variant={"outlined"}
              color={"darkPrimary"}
              onClick={toggleNewConfig}
            >
              {newConfig ? t("cancel") : t("add_rules")}
            </Button>{" "}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
