import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { resetPassword } from "../../../slices/authSlice";
import { setLoading } from "../../../slices/feedbackSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: "center",
    color: theme.palette.error.main,
    textTransform: "title",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ResetPassword(props) {
  const { t } = useTranslation("signin");

  const [fields, setFields] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.feedback.loading);
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);

  useEffect(() => {
    setFields({
      email: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "email",
        label: t("email_address"),
        name: "email",
        autoComplete: "email",
        value: "",
      },
    });
  }, [t]);

  const handleReset = (email) => {
    dispatch(setLoading(true));
    setError("");
    dispatch(resetPassword({ email }))
      .unwrap()
      .then(() => {
        setError("");
        setDone(true);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setError(err.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleReset(fields.email.value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("reset_title")}
        </Typography>
        {done ? (
          <Typography component="p" variant="body1" marginTop={"1rem"}>
            {t("reset_email_sent")}
          </Typography>
        ) : (
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField {...fields.email} />
              </Grid>
            </Grid>
            {error && (
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.errorMessage}>
                  {error}
                </Grid>
              </Grid>
            )}
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                {t("reset")}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        )}
      </div>
    </Container>
  );
}
