import { configureStore } from "@reduxjs/toolkit";

import feedbackReducer from "../slices/feedbackSlice";
import authReducer from "../slices/authSlice";
import accountReducer from "../slices/accountSlice";
import timesheetsReducer from "../slices/timesheetsSlice";
import projectReducer from "../slices/projectsSlice";

// Every reducer must be included here to be accessible by the useSelector hook
const reducer = {
  feedback: feedbackReducer,
  auth: authReducer,
  account: accountReducer,
  timesheets: timesheetsReducer,
  projects: projectReducer,
};

const store = configureStore({
  reducer,
  devTools: true,
});

export default store;
