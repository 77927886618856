import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getTimesheetsForUser,
  getTimesheetsForSelf,
  getFiscalYears,
  getUserSummary,
  getSummaryForSelf,
} from "../../../../slices/timesheetsSlice.js";
import Timesheets from "../TimeSheets/Timesheets";
import { Spinner } from "../../../Layout/Spinner/Spinner.jsx";
import Button from "@mui/material/Button";
import ConfigurationAssistantModal from "../../../ConfigurationAssistantModal/ConfigurationAssistantModal";
import {
  getOrganization,
  getSelf,
  getUser,
  getUsers,
} from "../../../../slices/accountSlice";
import { setLoading } from "../../../../slices/feedbackSlice";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
}));

export default function CurrentTimesheets() {
  const classes = useStyles();
  const { t } = useTranslation("timesheets");
  const dispatch = useDispatch();
  const [localLoading, setLocalLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const timesheets = useSelector((state) => state.timesheets.timesheets);
  const users = useSelector((state) => state.account.users);
  const self = useSelector((state) => state.account.self);
  const isAdmin = useSelector((state) => state.account.isAdmin);
  const isTeamAdmin = useSelector((state) => state.account.isTeamAdmin);
  const organization = useSelector((state) => state.account.organization);
  const [configurationModalOpen, setConfigurationModalOpen] = useState(false);
  const [displayPreviousYears, setDisplayPreviousYears] = useState(false);
  const fiscalYears = useSelector((state) => state.timesheets.fiscalYears);
  const dataLoading = useSelector((state) => state.feedback.loading);

  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    if (!organization.name && (isAdmin || isTeamAdmin)) {
      dispatch(getOrganization());
    }
  }, [isAdmin, organization]);

  useEffect(() => {
    if (isAdmin && !dataLoading && !fiscalYears.length) {
      dispatch(getFiscalYears())
        .unwrap()
        .then((newFiscalYears) => {
          if (!newFiscalYears.fiscalYears.length) {
            setConfigurationModalOpen(true);
          }
        })
        .catch(() => dispatch(setLoading(false)));
    }
  }, [isAdmin, dataLoading]);

  useEffect(() => {
    setLocalLoading(true);
    dispatch(setLoading(true));
    if (state && state.userId) {
      // Set user
      dispatch(getUser(state.userId));
      dispatch(getUserSummary(state.userId));
      const displayedUser = users.find((user) => user.id === state.userId);
      setUser(displayedUser);

      // Get TS
      dispatch(
        getTimesheetsForUser({
          userId: state.userId,
          displayPreviousYears,
        })
      )
        .unwrap()
        .then(() => {
          setLocalLoading(false);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          setLocalLoading(false);
          dispatch(setLoading(false));
        });
    } else {
      // Set user
      dispatch(getSelf());
      dispatch(getSummaryForSelf());
      setUser(self);
      // Get TS
      dispatch(getTimesheetsForSelf({ displayPreviousYears }))
        .unwrap()
        .then(() => {
          setLocalLoading(false);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          setLocalLoading(false);
          dispatch(setLoading(false));
        });
    }
  }, [state, displayPreviousYears]);

  useEffect(() => {
    if (!state || !state.userId) {
      setUser(self);
    } else {
      const displayedUser = users.find((user) => user.id === state.userId);
      setUser(displayedUser);
    }
  }, [self, users]);

  const getCurrentTimesheets = (timesheets) =>
    timesheets.filter(
      (timesheet) => new Date(timesheet.date_from) <= new Date()
    );

  const handleConfigurationModalClose = () => {
    setConfigurationModalOpen(false);
  };

  const handleSwitchDisplayPreviousYears = (value) => {
    const shouldDisplayPreviousYears = value.target.checked;
    setDisplayPreviousYears(shouldDisplayPreviousYears);
  };

  return (
    <>
      {configurationModalOpen && (
        <ConfigurationAssistantModal
          display={configurationModalOpen}
          handleClose={handleConfigurationModalClose}
        />
      )}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1"> {t("timesheets")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup classes={classes.formLabel}>
            <FormControlLabel
              control={
                <Switch
                  value={displayPreviousYears}
                  onChange={handleSwitchDisplayPreviousYears}
                />
              }
              label={t("display_previous_years")}
            />
          </FormGroup>
        </Grid>
        {(localLoading && !timesheets.length) ||
        (user && timesheets.length && timesheets[0].user_id !== user.id) ? (
          <Grid item xs={12}>
            <Spinner />
          </Grid>
        ) : timesheets.length ? (
          <Timesheets
            timesheets={getCurrentTimesheets(timesheets)}
            user={user}
          />
        ) : isAdmin ? (
          <>
            <Grid container spacing={2} paddingLeft={"1rem"}>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("welcome")}</Typography>
                <Typography variant="body1" marginTop={"1rem"}>
                  {" "}
                  {t("welcome_text_admin")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  onClick={() => navigate("/account")}
                >
                  {t("go_to_myaccount")}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={2} paddingLeft={"1rem"}>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("welcome")}</Typography>
                <Typography variant="body1" marginTop={"1rem"}>
                  {" "}
                  {t("no_ts_text_user")}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
