import CardHeader from "@mui/material/CardHeader";
import { Card, CardContent, Grid, Switch } from "@mui/material";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { updateOrganization } from "../../../../slices/accountSlice";
import { displayAlert } from "../../../../slices/feedbackSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

export default function OrganizationOptions({ organization }) {
  const { t } = useTranslation("myAccount");

  const [newOrganization, setNewOrganization] = useState(organization);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { id, checked } = event.target;
    const options = {
      ...newOrganization.options,
      [id]: checked,
    };
    const updatedOrganization = { ...organization, options };
    setNewOrganization(updatedOrganization);
    dispatch(updateOrganization(updatedOrganization))
      .unwrap()
      .then(() => {
        setNewOrganization(organization);
        dispatch(displayAlert(t("options.success"), "success"));
      })
      .catch(() => {
        setNewOrganization(organization);
      });
  };

  return (
    <Card>
      <CardHeader
        title={t("options.title")}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4">
                  {t("options.projects.title")}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id={"projects"}
                        checked={newOrganization?.options?.projects}
                        onChange={handleChange}
                      />
                    }
                    label={t("options.projects.activate")}
                  />
                  <Typography variant="h5">{}</Typography>

                  <FormControlLabel
                    control={
                      <Switch
                        id={"projects_mandatory"}
                        checked={newOrganization?.options?.projects_mandatory}
                        onChange={handleChange}
                      />
                    }
                    label={t("options.projects.projects_status")}
                  />
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <InfoBox level="info">{t("options.projects.info")}</InfoBox>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4">
                  {t("options.prefill_timesheets.title")}
                </Typography>
                <Switch
                  id={"prefill_timesheets"}
                  checked={newOrganization?.options?.prefill_timesheets}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={12}>
                <InfoBox level="info">
                  {t("options.prefill_timesheets.info")}
                </InfoBox>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
