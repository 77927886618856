import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import makeStyles from "@mui/styles/makeStyles";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import InviteUserModal from "./InviteUserModal/InviteUserModal";
import UserSettingsModal from "./UserSettingsModal/UserSettingsModal";
import UserRulesModal from "./UserRulesModal/UserRulesModal";
import UserSummary from "./UserSummary/UserSummary";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import SubscriptionExpiredModal from "./SubscriptionExpiredModal/SubscriptionExpiredModal";
import ReminderModal from "./ReminderModal/ReminderModal";
import { getUser, getUsers } from "../../../slices/accountSlice";
import { setLoading } from "../../../slices/feedbackSlice";
import { getUserSummary } from "../../../slices/timesheetsSlice";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TooManyUsersModal from "./TooManyUsersModal/TooManyUsersModal";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
  divider: {
    width: "100%",
    margin: "1rem 0",
  },
  formLabel: {
    width: "max-content",
  },
}));

export default function Users() {
  const classes = useStyles();
  const { t } = useTranslation("users");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.account.users);
  const userSummaries = useSelector((state) => state.timesheets.userSummaries);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [tooManyUsersModalOpen, setTooManyUsersModalOpen] = useState(false);
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [userSettingsModalOpen, setUserSettingsModalOpen] = useState(false);
  const [userRulesModalOpen, setUserRulesModalOpen] = useState(false);
  const [subscriptionExpiredModalOpen, setSubscriptionExpiredModalOpen] =
    useState(false);
  const [expirationStatus, setExpirationStatus] = useState("");
  const [userToUpdate, setUserToUpdate] = useState({});
  const [displayArchived, setDisplayArchived] = useState(false);

  const organization = useSelector((state) => state.account.organization);
  const isAdmin = useSelector((state) => state.account.isAdmin);
  const isTeamAdmin = useSelector((state) => state.account.isTeamAdmin);

  useEffect(() => {
    if (!organization.is_subscribed) {
      if (organization.trial_expiration_status) {
        setExpirationStatus(organization.trial_expiration_status);
        setSubscriptionExpiredModalOpen(true);
      }
    }
  }, [organization]);

  useEffect(() => {
    if (isAdmin || isTeamAdmin) {
      dispatch(setLoading(true));
      dispatch(getUsers({ displayArchived }))
        .unwrap()
        .then((response) =>
          response.users.forEach((user) => dispatch(getUserSummary(user.id)))
        )
        .then(() => dispatch(setLoading(false)))
        .catch(() => dispatch(setLoading(false)));
    }
  }, []);

  const handleSwitchDisplayArchived = (value) => {
    const shouldDisplayArchived = value.target.checked;
    setDisplayArchived(shouldDisplayArchived);
    dispatch(getUsers({ displayArchived: shouldDisplayArchived }))
      .unwrap()
      .then((response) =>
        response.users.forEach((user) => dispatch(getUserSummary(user.id)))
      );
  };
  const handleInviteUserModalOpen = () => {
    if (organization.users_left_in_plan > 0) {
      setInviteUserModalOpen(true);
    } else {
      setTooManyUsersModalOpen(true);
    }
  };

  const handleInviteUserModalClose = () => {
    setInviteUserModalOpen(false);
  };
  const handleTooManyUsersModalOpen = () => {
    setTooManyUsersModalOpen(false);
  };

  const handleReminderModalClose = () => {
    setReminderModalOpen(false);
  };

  const handleReminderModalOpen = () => {
    setReminderModalOpen(true);
  };

  const handleUserSettingsModalClose = () => {
    setUserSettingsModalOpen(false);
    setUserToUpdate({});
  };

  const handleUserSettingsModalOpen = (user) => {
    setUserToUpdate(user);
    setUserSettingsModalOpen(true);
  };

  const handleUserRulesModalClose = () => {
    setUserRulesModalOpen(false);
    setUserToUpdate({});
  };

  const handleUserRulesModalOpen = (user) => {
    setUserToUpdate(user);
    setUserRulesModalOpen(true);
  };

  const refreshUserToUpdate = () => {
    dispatch(getUser(userToUpdate.id))
      .unwrap()
      .then((response) => setUserToUpdate(response.user));
  };

  const handleSubscriptionExpiredModalClose = () => {
    setSubscriptionExpiredModalOpen(false);
  };

  return (
    <>
      {subscriptionExpiredModalOpen && (
        <SubscriptionExpiredModal
          display={subscriptionExpiredModalOpen}
          handleClose={handleSubscriptionExpiredModalClose}
          expirationStatus={expirationStatus}
        />
      )}
      {inviteUserModalOpen && (
        <InviteUserModal
          display={inviteUserModalOpen}
          handleClose={handleInviteUserModalClose}
        />
      )}
      {tooManyUsersModalOpen && (
        <TooManyUsersModal
          display={tooManyUsersModalOpen}
          handleClose={handleTooManyUsersModalOpen}
          limit={organization.users_allowed_in_plan}
        />
      )}
      {reminderModalOpen && (
        <ReminderModal
          display={reminderModalOpen}
          handleClose={handleReminderModalClose}
        />
      )}
      {userSettingsModalOpen && (
        <UserSettingsModal
          display={userSettingsModalOpen}
          handleClose={handleUserSettingsModalClose}
          user={userToUpdate}
        />
      )}
      {userRulesModalOpen && (
        <UserRulesModal
          display={userRulesModalOpen}
          handleClose={handleUserRulesModalClose}
          user={userToUpdate}
          configs={userToUpdate.configs}
          orgConfigs={userToUpdate.organization_configs}
          refreshUser={refreshUserToUpdate}
        />
      )}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h1"> {t("team")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup classes={classes.formLabel}>
            <FormControlLabel
              control={
                <Switch
                  value={displayArchived}
                  onChange={handleSwitchDisplayArchived}
                />
              }
              label={t("display_archived")}
            />
          </FormGroup>
        </Grid>
        {/* <Grid item xs={12}> */}
        {/*   <InfoBox level={"info"}>{t("info")}</InfoBox> */}
        {/* </Grid> */}
        <Grid item xs={12} sm={6}>
          {isAdmin && (
            <Button
              variant="outlined"
              component="label"
              color="darkSecondary"
              onClick={handleInviteUserModalOpen}
            >
              {t("invite_user")}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={6} display={"flex"} justifyContent={"right"}>
          <Button
            variant="outlined"
            component="label"
            color="darkPrimary"
            onClick={handleReminderModalOpen}
          >
            {t("send_reminder")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={6} columnSpacing={6}>
            {[...users]
              .sort(
                (a, b) =>
                  b.first_name.toLowerCase() < a.first_name.toLowerCase()
              )
              .map((user) => {
                // eslint-disable-next-line react/display-name

                return (
                  <Grid item xs={12} md={6} key={user.email}>
                    <Card>
                      <CardHeader
                        title={`${user.first_name} ${user.last_name}`}
                        titleTypographyProps={{ variant: "h3" }}
                        subheader={user.signed_up ? null : t("not_signed_up")}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <UserSummary
                            user={user}
                            userSummary={userSummaries[user.id]}
                          />
                          <Divider className={classes.divider} />
                          <Grid
                            item
                            xs={12}
                            md={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            {isAdmin && (
                              <>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="darkPrimary"
                                  onClick={() =>
                                    handleUserSettingsModalOpen(user)
                                  }
                                >
                                  {t("settings")}
                                </Button>

                                <Button
                                  variant="outlined"
                                  component="label"
                                  color="darkPrimary"
                                  onClick={() => handleUserRulesModalOpen(user)}
                                >
                                  {t("rules")}
                                </Button>
                              </>
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              component="label"
                              onClick={() =>
                                navigate("/current-timesheets", {
                                  state: { userId: user.id },
                                })
                              }
                            >
                              {t("timesheets")}
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
