import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

// Organizations
const getOrganization = () =>
  axios.get(`${API_URL}/accounts/organization/`, { headers: authHeader() });

const updateOrganization = (organizationData) => {
  const data = organizationData;
  return axios.put(`${API_URL}/accounts/organization/`, data, {
    headers: authHeader(),
  });
};

const createOrgConfig = (configData) => {
  const sickTimePerPeriod = {
    time: configData.sickValue,
    unit: configData.unit,
    period: configData.period,
  };

  const vacationTimePerPeriod = {
    time: configData.vacationValue,
    unit: configData.unit,
    period: configData.period,
  };

  const data = {
    valid_from: configData.validFrom || "2020-01-01",
    worked_hours_per_week: configData.hoursPerWeek || 0,
    sick_time_per_period: sickTimePerPeriod,
    vacation_time_per_period: vacationTimePerPeriod,
    max_cumulated_sick_hours: configData.maxCumulatedSickHours || null, // Not implemented in front yet
    max_cumulated_vacation_hours: configData.maxCumulatedVacation || 0, // Not implemented in front yet
    max_cumulated_overtime_hours:
      configData.maxCumulatedOvertimeHours === ""
        ? null
        : configData.maxCumulatedOvertimeHours,
    max_reported_sick_hours: configData.maxReportedSick || 0, // Not implemented in front yet
    max_reported_vacation_hours: configData.maxReportedVacation || 0, // Not implemented in front yet
    max_reported_overtime_hours: configData.maxReportedOvertme || 0, // Not implemented in front yet
    worked_days: configData.workedDays,
    displayed_time_period: configData.period,
    displayed_time_unit: configData.unit,
    public_holidays: configData.publicHolidays,
    overtime_xtra: configData.overtimeExtraConfig || null,
    compensatory_days_per_year: configData.compensatoryDaysPerYear || null, // Not implemented in front yet,
    sick_days_method: configData.sickDaysMethod || "progressive", // Not implemented in front yet,
    sick_days_reset: configData.sickDaysReset || "never", // Not implemented in front yet,
    sick_days_date: configData.sickDaysDate || null, // Not implemented in front yet,
    vacation_days_method: configData.vacationDaysMethod || "progressive", // Not implemented in front yet,
    vacation_days_reset: configData.vacationDaysReset || "never", // Not implemented in front yet,
    vacation_days_date: configData.vacationDaysDate || null, // Not implemented in front yet,
  };

  return axios.post(`${API_URL}/accounts/organization/config/`, data, {
    headers: authHeader(),
  });
};

const updateOrgConfig = (configData) => {
  const sickTimePerPeriod = {
    time: configData.sickValue,
    unit: configData.unit,
    period: configData.period,
  };

  const vacationTimePerPeriod = {
    time: configData.vacationValue,
    unit: configData.unit,
    period: configData.period,
  };

  const configId = configData.id;

  const data = {
    valid_from: configData.validFrom || "2020-01-01",
    worked_hours_per_week: configData.hoursPerWeek || 0,
    sick_time_per_period: sickTimePerPeriod,
    vacation_time_per_period: vacationTimePerPeriod,
    max_cumulated_sick_hours: configData.maxCumulatedSickHours || 0, // Not implemented in front yet
    max_cumulated_vacation_hours: configData.maxCumulatedVacation || 0, // Not implemented in front yet
    max_cumulated_overtime_hours:
      configData.maxCumulatedOvertimeHours === ""
        ? null
        : configData.maxCumulatedOvertimeHours,
    max_reported_sick_hours: configData.maxReportedSick || 0, // Not implemented in front yet
    max_reported_vacation_hours: configData.maxReportedVacation || 0, // Not implemented in front yet
    max_reported_overtime_hours: configData.maxReportedOvertme || 0, // Not implemented in front yet
    worked_days: configData.workedDays,
    displayed_time_period: configData.period,
    displayed_time_unit: configData.unit,
    public_holidays: configData.publicHolidays,
    overtime_xtra: configData.overtimeExtraConfig || null, // Not implemented in front yet,
    compensatory_days_per_year: configData.compensatoryDaysPerYear || null, // Not implemented in front yet,
    sick_days_method: configData.sickDaysMethod || null, // Not implemented in front yet,
    sick_days_reset: configData.sickDaysReset || null, // Not implemented in front yet,
    sick_days_date: configData.sickDaysDate || null, // Not implemented in front yet,
    vacation_days_method: configData.vacationDaysMethod || null, // Not implemented in front yet,
    vacation_days_reset: configData.vacationDaysReset || null, // Not implemented in front yet,
    vacation_days_date: configData.vacationDaysDate || null, // Not implemented in front yet,
  };

  return axios.put(
    `${API_URL}/accounts/organization/config/${configId}/`,
    data,
    {
      headers: authHeader(),
    }
  );
};

// Teams
const getTeams = () => {
  return axios.get(`${API_URL}/accounts/teams/`, {
    headers: authHeader(),
  });
};

const createTeam = (teamData) => {
  const data = {
    name: teamData.name,
    users: teamData.users,
    admins: teamData.admins,
  };

  return axios.post(`${API_URL}/accounts/team/`, data, {
    headers: authHeader(),
  });
};

const updateTeam = (teamData) => {
  const data = {
    name: teamData.name,
    users: teamData.users,
    admins: teamData.admins,
  };

  const teamId = teamData.id;

  return axios.put(`${API_URL}/accounts/team/${teamId}/`, data, {
    headers: authHeader(),
  });
};
const deleteTeam = (teamId) => {
  return axios.delete(`${API_URL}/accounts/team/${teamId}/`, {
    headers: authHeader(),
  });
};

// Users
const getInvitedUser = (token) => {
  return axios.get(`${API_URL}/accounts/signup/${token}/`, {
    headers: authHeader(),
  });
};

const signupUser = (userData) => {
  return axios.post(`${API_URL}/accounts/signup/`, userData, {
    headers: authHeader(),
  });
};

const getUsers = ({ displayArchived }) => {
  return axios.get(`${API_URL}/accounts/users/`, {
    params: { "display-archived": displayArchived },
    headers: authHeader(),
  });
};

const getUser = (userId) => {
  return axios.get(`${API_URL}/accounts/users/${userId}/`, {
    headers: authHeader(),
  });
};

const getSelf = () => {
  return axios.get(`${API_URL}/accounts/users/self/`, {
    headers: authHeader(),
  });
};

const inviteUser = (userData) => {
  const sickTimePerPeriod = {
    time: userData.sickValue || 0,
    unit: userData.unit,
    period: userData.period,
  };

  const vacationTimePerPeriod = {
    time: userData.vacationValue || 0,
    unit: userData.unit,
    period: userData.period,
  };

  const data = {
    first_name: userData.firstName,
    last_name: userData.lastName,
    email: userData.email,
    contract_start_date: userData.contractStartDate,
    initial_cumulated_sick_hours: userData.reportedSick,
    initial_cumulated_vacation_hours: userData.reportedVacation,
    initial_cumulated_overtime_hours: userData.reportedOvertime,
    configs: [
      {
        is_specific: userData.isSpecific,
        valid_from: userData.contractStartDate, // For new user, config start with his contract
        worked_hours_per_week: userData.hoursPerWeek || null,
        sick_time_per_period: sickTimePerPeriod,
        vacation_time_per_period: vacationTimePerPeriod,
        max_cumulated_sick_hours: userData.maxCumulatedSick || null, // Not implemented in front yet
        max_cumulated_vacation_hours: userData.maxCumulatedVacation || null, // Not implemented in front yet
        max_cumulated_overtime_hours: userData.maxCumulatedOvertime || null, // Not implemented in front yet
        max_reported_sick_hours: userData.maxReportedSick || null, // Not implemented in front yet
        max_reported_vacation_hours: userData.maxReportedVacation || null, // Not implemented in front yet
        max_reported_overtime_hours: userData.maxReportedOvertme || null, // Not implemented in front yet
        worked_days: userData.workedDays,
        compensatory_days_per_year: userData.compensatoryDaysPerYear || null, // Not implemented in front yet,
      },
    ],
  };
  return axios.post(`${API_URL}/accounts/invite-user/`, data, {
    headers: authHeader(),
  });
};

const updateUser = (userData) => {
  const userId = userData.id;

  const data = {
    first_name: userData.firstName,
    last_name: userData.lastName,
    email: userData.email,
    contract_start_date: userData.contractStartDate,
    contract_end_date: userData.contractEndDate,
    initial_cumulated_sick_hours: userData.reportedSick,
    initial_cumulated_vacation_hours: userData.reportedVacation,
    initial_cumulated_overtime_hours: userData.reportedOvertime,
    archived: userData.archived,
  };
  return axios.put(`${API_URL}/accounts/user/${userId}/`, data, {
    headers: authHeader(),
  });
};

const updateUserConfig = (userConfig) => {
  const sickTimePerPeriod = {
    time: userConfig.sickValue,
    unit: userConfig.unit,
    period: userConfig.period,
  };

  const vacationTimePerPeriod = {
    time: userConfig.vacationValue,
    unit: userConfig.unit,
    period: userConfig.period,
  };

  const configId = userConfig.id;

  const data = {
    is_specific: userConfig.isSpecific,
    valid_from: userConfig.validFrom || "2020-01-01",
    worked_hours_per_week: userConfig.hoursPerWeek || null,
    sick_time_per_period: sickTimePerPeriod,
    vacation_time_per_period: vacationTimePerPeriod,
    max_cumulated_sick_hours: userConfig.maxCumulatedSickHours || 0, // Not implemented in front yet
    max_cumulated_vacation_hours: userConfig.maxCumulatedVacation || null, // Not implemented in front yet
    max_cumulated_overtime_hours: userConfig.maxCumulatedOvertime || null, // Not implemented in front yet
    max_reported_sick_hours: userConfig.maxReportedSick || null, // Not implemented in front yet
    max_reported_vacation_hours: userConfig.maxReportedVacation || null, // Not implemented in front yet
    max_reported_overtime_hours: userConfig.maxReportedOvertme || null, // Not implemented in front yet
    worked_days: userConfig.workedDays,
    overtime_xtra: userConfig.overtimeExtraConfig || null, // Not implemented in front yet,
    compensatory_days_per_year: userConfig.compensatoryDaysPerYear || null, // Not implemented in front yet,
  };
  return axios.put(`${API_URL}/accounts/user/config/${configId}/`, data, {
    headers: authHeader(),
  });
};

const createUserConfig = (userConfig) => {
  const sickTimePerPeriod = {
    time: userConfig.sickValue,
    unit: userConfig.unit,
    period: userConfig.period,
  };

  const vacationTimePerPeriod = {
    time: userConfig.vacationValue,
    unit: userConfig.unit,
    period: userConfig.period,
  };

  const data = {
    user_id: userConfig.userId,
    is_specific: userConfig.isSpecific,
    valid_from: userConfig.validFrom || "2020-01-01",
    worked_hours_per_week: userConfig.hoursPerWeek || null,
    sick_time_per_period: sickTimePerPeriod,
    vacation_time_per_period: vacationTimePerPeriod,
    max_cumulated_sick_hours: userConfig.maxCumulatedSickHours || null, // Not implemented in front yet
    max_cumulated_vacation_hours: userConfig.maxCumulatedVacation || null, // Not implemented in front yet
    max_cumulated_overtime_hours: userConfig.maxCumulatedOvertime || null, // Not implemented in front yet
    max_reported_sick_hours: userConfig.maxReportedSick || null, // Not implemented in front yet
    max_reported_vacation_hours: userConfig.maxReportedVacation || null, // Not implemented in front yet
    max_reported_overtime_hours: userConfig.maxReportedOvertme || null, // Not implemented in front yet
    worked_days: userConfig.workedDays,
    overtime_xtra: userConfig.overtimeExtraConfig || null, // Not implemented in front yet,
    compensatory_days_per_year: userConfig.compensatoryDaysPerYear || null, // Not implemented in front yet,
  };
  return axios.post(`${API_URL}/accounts/user/config/`, data, {
    headers: authHeader(),
  });
};

const accountService = {
  getOrganization,
  updateOrganization,
  createOrgConfig,
  inviteUser,
  getUsers,
  getTeams,
  createTeam,
  updateTeam,
  deleteTeam,
  getInvitedUser,
  signupUser,
  updateUser,
  updateUserConfig,
  updateOrgConfig,
  getSelf,
  getUser,
  createUserConfig,
};

export default accountService;
