import Login from "../components/Screens/Login/Login";
import Signup from "../components/Screens/Signup/Signup";
import UserSignup from "../components/Screens/UserSignup/UserSignup";
import ResetPassword from "../components/Screens/ResetPassword/ResetPassword";
import ResetPasswordVerify from "../components/Screens/ResetPassword/ResetPasswordVerify";
import MyAccount from "../components/Screens/MyAccount/MyAccount";
import CurrentTimesheets from "../components/Screens/Timesheets/CurrentTimesheets/CurrentTimesheets";
import FutureTimesheets from "../components/Screens/Timesheets/FutureTimesheets/FutureTimesheets";
import Timesheet from "../components/Screens/Timesheet/Timesheet";
import Users from "../components/Screens/Users/Users";
import Reports from "../components/Screens/Reports/Reports";
import Projects from "../components/Screens/Projects/Projects";
import Teams from "../components/Screens/Teams/Teams";
import TODO from "../components/Screens/TODO/TODO";

const routes = {
  default: {
    path: { admin: "/users", user: "/current-timesheets" },
  },
  logoRedirect: "/users",
  authentication: {
    login: {
      name: "login",
      path: "/login",
      component: Login,
      minimalLayout: true,
      inNav: false,
    },
    signup: {
      name: "signup",
      path: "/signup",
      component: Signup,
      minimalLayout: true,
      inNav: false,
    },
    userSignup: {
      name: "userSignup",
      path: "/user-signup",
      component: UserSignup,
      minimalLayout: true,
      inNav: false,
    },
  },
  protected: [
    {
      name: "myAccount",
      path: "/account",
      component: MyAccount,
      minimalLayout: false,
      inNav: true,
      admin: true,
    },
    {
      name: "reports",
      path: "/reports",
      component: Reports,
      minimalLayout: false,
      inNav: true,
      admin: true,
    },
    {
      name: "projects",
      path: "/projects",
      component: Projects,
      minimalLayout: false,
      inNav: true,
      admin: true,
      optionName: "projects",
    },
    {
      name: "teams",
      path: "/teams",
      component: Teams,
      minimalLayout: false,
      inNav: true,
      admin: true,
      planTypes: ["large", "team"],
    },
    {
      name: "users",
      path: "/users",
      component: Users,
      minimalLayout: false,
      inNav: true,
      teamAdmin: true,
    },
    {
      name: "todo",
      path: "/todo",
      component: TODO,
      minimalLayout: false,
      inNav: true,
      teamAdmin: true,
    },
    {
      name: "currentTimesheets",
      path: "/current-timesheets",
      component: CurrentTimesheets,
      minimalLayout: false,
      inNav: true,
      admin: false,
    },
    {
      name: "futureTimesheets",
      path: "/future-timesheets",
      component: FutureTimesheets,
      minimalLayout: false,
      inNav: true,
      admin: false,
    },
    {
      name: "Timesheet",
      path: "/timesheet/:id",
      component: Timesheet,
      minimalLayout: false,
      inNav: false,
      admin: false,
    },
  ],
  public: [
    {
      name: "passwordReset",
      path: "/password/reset",
      component: ResetPassword,
      minimalLayout: true,
      inNav: false,
    },
    {
      name: "passwordResetVerify",
      path: "/password/verify",
      component: ResetPasswordVerify,
      minimalLayout: true,
      inNav: false,
    },
  ],
  admin: [],
};

export default routes;
