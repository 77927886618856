import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import { NavLink } from "react-router-dom";
import routes from "../../../config/routes";
import Logo from "../../Logo/Logo";
import Loading from "../Feedback/Loading/Loading";
import LanguageIcon from "@mui/icons-material/Translate";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NoSsr from "@mui/material/NoSsr";
import { LANGUAGES_LABELS } from "../../../config/config";

import { useTranslation } from "react-i18next";
import { setLocale } from "../../../utils/helpers";

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },

  signOutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
  },
  button: {
    margin: theme.spacing(1),
  },
  language: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  appName: {
    color: theme.palette.white,
    fontFamily: "Roboto",
    marginLeft: "20px",
  },
}));

const TopMenu = ({ onSidebarOpen, minimalLayout }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("dashboard");
  const userLanguage = i18n.language;
  const [languageMenu, setLanguageMenu] = React.useState(null);

  const handleLanguageIconClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageMenuClose = (event) => {
    if (event.currentTarget.nodeName === "A") {
      document.cookie = `userLanguage=noDefault;path=/;max-age=31536000`;
    }
    setLanguageMenu(null);
  };

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setLocale(languageCode);
    setLanguageMenu(null);
  };

  const languageButton = (
    <>
      <Button
        color={"inherit"}
        aria-owns={languageMenu ? "language-menu" : undefined}
        aria-haspopup="true"
        aria-label={t("changeLanguage")}
        onClick={handleLanguageIconClick}
      >
        <LanguageIcon />
        <span className={classes.language}>
          {
            LANGUAGES_LABELS.filter(
              (language) => language.code === userLanguage
            )[0]?.text
          }
        </span>
        <ExpandMoreIcon fontSize="small" />
      </Button>
      <NoSsr defer>
        <Menu
          id="language-menu"
          anchorEl={languageMenu}
          open={Boolean(languageMenu)}
          onClose={handleLanguageMenuClose}
        >
          {LANGUAGES_LABELS.map((language) => (
            <MenuItem
              component="a"
              data-no-link="true"
              key={language.code}
              selected={userLanguage === language.code}
              onClick={() => handleLanguageChange(language.code)}
              lang={language.code}
              hrefLang={language.code}
            >
              {language.text}
            </MenuItem>
          ))}
        </Menu>
      </NoSsr>
    </>
  );

  const landingUrl =
    userLanguage === "fr"
      ? "https://www.feuilledetemps.ca"
      : "https://www.feuilledetemps.ca/en";

  let layout;
  if (minimalLayout) {
    layout = (
      <AppBar>
        <Toolbar>
          <a href={landingUrl} style={{ textDecoration: "none" }}>
            <IconButton color="secondary" size="large">
              <Logo />
            </IconButton>
          </a>
          <div className={classes.flexGrow} />
          {languageButton}
        </Toolbar>
        <Loading />
      </AppBar>
    );
  } else {
    layout = (
      <AppBar>
        <Toolbar>
          <NavLink to={routes.logoRedirect}>
            <IconButton color="primary" size="large">
              <Logo />
            </IconButton>
          </NavLink>
          <div className={classes.flexGrow} />
          {languageButton}

          <Button onClick={onSidebarOpen} color={"inherit"}>
            <MenuIcon />
            {t("Menu")}
          </Button>
        </Toolbar>
        <Loading />
      </AppBar>
    );
  }

  return layout;
};

TopMenu.defaultProps = {
  minimalLayout: true,
  onSidebarOpen: () => {},
};
TopMenu.propTypes = {
  minimalLayout: PropTypes.bool,
  onSidebarOpen: PropTypes.func,
};

export default TopMenu;
