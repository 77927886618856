import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { round } from "../../../../../../utils/helpers";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Simulation({ userSummary }) {
  const { t } = useTranslation("users");

  return (
    <Grid
      item
      xs={12}
      md={12}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h5">{t("sick")} :</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>
            {round(userSummary.sick_hours || 0)} {t("hours")} (
            {round(userSummary.sick_days || 0)} {t("days")})
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h5">{t("vacation")} :</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>
            {round(userSummary.vacation_hours || 0)} {t("hours")} (
            {round(userSummary.vacation_days || 0)} {t("days")})
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
