import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createCheckoutLink = (data) =>
  axios.post(`${API_URL}/payment/create/`, data, { headers: authHeader() });

const createCustomerPortalLink = () =>
  axios.post(
    `${API_URL}/payment/customer-portal/`,
    {},
    { headers: authHeader() }
  );

const organizationService = {
  createCheckoutLink,
  createCustomerPortalLink,
};

export default organizationService;
