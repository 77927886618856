import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Navigate } from "react-router-dom";
import { signup, setHasAccount, login } from "../../../slices/authSlice";
import { displayAlert } from "../../../slices/feedbackSlice";
import { LogoSignup } from "../../Logo/Logo";

const useStyles = makeStyles((theme) => ({
  welcome: {
    backgroundColor: theme.palette.primary.light,
    minHeight: "100vh",
    padding: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(8),
      minHeight: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(16),
    },
  },
  main: {
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "8vh",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: "center",
    color: theme.palette.error.main,
    textTransform: "title",
  },
  hasAccount: {
    textAlign: "right",
  },
  logo: {
    width: "200px",
    height: "200px",
  },
  freeDemoLink: {
    color: theme.palette.text.secondary,
  },
}));

export default function Signup(props) {
  const { t } = useTranslation("signin");

  const [fields, setFields] = useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasAccount = useSelector((state) => state.auth.hasAccount);
  const loading = useSelector((state) => state.feedback.loading);
  const [error, setError] = useState("");
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    setFields({
      email: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "email",
        label: t("email_address"),
        name: "email",
        autoComplete: "email",
        value: "",
      },
      password: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "password",
        label: t("password"),
        type: "password",
        id: "password",
        autoComplete: "current-password",
        value: "",
      },
      firstName: {
        autoComplete: "firstName",
        name: "firstName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "firstName",
        label: t("firstName"),
        value: "",
      },
      lastName: {
        autoComplete: "lastName",
        name: "lastName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "lastName",
        label: t("lastName"),
        value: "",
      },
      organization: {
        autoComplete: "organization",
        name: "organization",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "organization",
        label: t("organization"),
        value: "",
      },
    });
  }, [t]);

  if (hasAccount) {
    return <Navigate to="/login" />;
  }

  const handleSignup = (firstName, lastName, email, password, organization) => {
    setError("");

    dispatch(
      signup({
        firstName,
        lastName,
        email,
        password,
        organization,
      })
    )
      .unwrap()
      .then(() => {
        setError("");
        dispatch(displayAlert(t("account_created"), "success"));
        dispatch(login({ password, email }));
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    handleSignup(
      fields.firstName.value,
      fields.lastName.value,
      fields.email.value,
      fields.password.value,
      fields.organization.value
    );
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={6} className={classes.welcome}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            display="flex"
            marginBottom={8}
          >
            <LogoSignup />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h1" color="text.error">
              {t("welcome.title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body2">
              {t("welcome.p1")}
              <strong>{t("welcome.p1_b")}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body2">
              {t("welcome.p2")}
              <strong>{t("welcome.p2_b")}</strong>
              {t("welcome.p2_end")}
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={0}>
            <Typography component="p" variant="body2">
              {t("welcome.free_demo")}
              <a
                href={
                  "https://fantastical.app/aynils/presentation-de-feuille-de-temps"
                }
                className={classes.freeDemoLink}
              >
                {t("welcome.free_demo_link")}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} display="flex">
        <Container component="main" maxWidth="sm" className={classes.main}>
          <div className={classes.paper}>
            <Typography component="h2" variant="h3" marginBottom={3}>
              {hasAccount ? t("signin") : t("signup")}
            </Typography>
            {/* {!hasAccount && ( */}
            {/*   // <Grid item xs={12}> */}
            {/*   <InfoBox level={"info"}>{t("info")}</InfoBox> */}
            {/*   // </Grid> */}
            {/* )} */}
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField {...fields.organization} />
                </Grid>
                <Grid item xs={12}>
                  <TextField {...fields.firstName} />
                </Grid>
                <Grid item xs={12}>
                  <TextField {...fields.lastName} />
                </Grid>

                <Grid item xs={12}>
                  <TextField {...fields.email} />
                </Grid>
                <Grid item xs={12}>
                  <TextField {...fields.password} />
                </Grid>
              </Grid>
              {error && (
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.errorMessage}>
                    {error}
                  </Grid>
                </Grid>
              )}

              <div className={classes.wrapper}>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  loading={loading}
                >
                  {t("signup_button")}
                </LoadingButton>
              </div>
              <Grid container>
                <Grid item xs={12} className={classes.hasAccount}>
                  <Link
                    href=""
                    onClick={() => dispatch(setHasAccount(true))}
                    variant="body2"
                    color={"secondary.dark"}
                  >
                    {t("already_have_an_account")}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
}
