import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import PaymentService from "../../../../services/payment.service";

import Plans from "./Plans/Plans";
import { displayAlert } from "../../../../slices/feedbackSlice";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    justifyContent: "space-between",
  },
}));

export default function SubscribeModal({ display, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("myAccount");

  const handleSelectPlan = (planId) => {
    setSelectedPlanId(planId);
  };

  const handleStripeCheckout = () => {
    setLoading(true);
    const priceId = selectedPlanId;
    PaymentService.createCheckoutLink({ priceId })
      .then((result) => (window.location.href = result.data.url))
      .catch((err) => dispatch(displayAlert(t("checkout_error"), "error")));
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth maxWidth={"lg"}>
      <DialogTitle variant="h3">
        {t("subscribe_modal.title")}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Plans
              selectedPlanId={selectedPlanId}
              handleSelectPlan={handleSelectPlan}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography type="p" variant="body1">
              * {t("subscribe_modal.reduction")}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          component="label"
          color="secondary"
          onClick={handleStripeCheckout}
          loading={loading}
          disabled={!selectedPlanId}
        >
          {t("subscribe_modal.pay_button")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
