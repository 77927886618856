import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getTimesheetsForUser,
  getTimesheetsForSelf,
} from "../../../../slices/timesheetsSlice.js";
import Timesheets from "../TimeSheets/Timesheets";
import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import { Spinner } from "../../../Layout/Spinner/Spinner.jsx";
import SimulationModal from "./SimulationModal/SimulationModal";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
}));

export default function FutureTimesheets() {
  const classes = useStyles();
  const { t } = useTranslation("timesheets");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const timesheets = useSelector((state) => state.timesheets.timesheets);
  const users = useSelector((state) => state.account.users);
  const self = useSelector((state) => state.account.self);
  const isAdmin = useSelector((state) => state.account.isAdmin);
  const [displaySimulationModal, setDisplaySimulationModal] = useState(false);

  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    setLoading(true);
    if (state && state.userId) {
      // Get TS
      dispatch(
        getTimesheetsForUser({
          userId: state.userId,
          displayPreviousYears: false,
        })
      )
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      // Get TS
      dispatch(getTimesheetsForSelf({ displayPreviousYears: false }))
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [state, self]);

  const getFutureTimesheets = (timesheets) =>
    timesheets
      .filter((timesheet) => new Date(timesheet.date_from) > new Date())
      .sort((a, b) => new Date(a.date_from) - new Date(b.date_from));

  const handleCloseSimulationModal = () => {
    setDisplaySimulationModal(false);
  };
  const handleOpenSimulationModal = () => {
    setDisplaySimulationModal(true);
  };

  return (
    <>
      <SimulationModal
        display={displaySimulationModal}
        handleClose={handleCloseSimulationModal}
      />
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h1"> {t("future")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <InfoBox level={"info"}>
            {t("simulation_presentation_1")}
            <br />
            {t("simulation_presentation_2")}
          </InfoBox>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            component="label"
            color="darkPrimary"
            onClick={handleOpenSimulationModal}
          >
            {t("open_simulation_modal")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {timesheets[0]?.user && (
            <InfoBox level={"info"}>
              {t("future_timesheets_user_description.part1")}&#8239;
              <Typography component="span" variant="h5">
                {timesheets[0]?.user}
              </Typography>
              {t("future_timesheets_user_description.part2")}
            </InfoBox>
          )}
        </Grid>
        {loading ? (
          <Spinner />
        ) : timesheets.length ? (
          <Timesheets
            timesheets={getFutureTimesheets(timesheets)}
            user={null}
          />
        ) : isAdmin ? (
          <>
            <Grid container spacing={2} paddingLeft={"1rem"}>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("welcome")}</Typography>
                <Typography variant="body1" marginTop={"1rem"}>
                  {" "}
                  {t("welcome_text_admin")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  onClick={() => navigate("/account")}
                >
                  {t("go_to_myaccount")}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={2} paddingLeft={"1rem"}>
              <Grid item xs={12}>
                <Typography variant="h5"> {t("welcome")}</Typography>
                <Typography variant="body1" marginTop={"1rem"}>
                  {" "}
                  {t("no_ts_text_user")}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
