import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

// Projects
const getProjects = () =>
  axios.get(`${API_URL}/projects/`, { headers: authHeader() });

const createProject = (projectData) => {
  const data = {
    name: projectData.name,
    status: projectData.status,
    users: projectData.users,
  };

  return axios.post(`${API_URL}/projects/`, data, {
    headers: authHeader(),
  });
};

const updateProject = (projectData) => {
  const data = {
    name: projectData.name,
    status: projectData.status,
    users: projectData.users,
  };

  const projectId = projectData.id;

  return axios.put(`${API_URL}/projects/${projectId}/`, data, {
    headers: authHeader(),
  });
};
const deleteProject = (projectId) => {
  return axios.delete(`${API_URL}/projects/${projectId}/`, {
    headers: authHeader(),
  });
};

const getProjectsForUser = (userId) =>
  axios.get(`${API_URL}/projects/user/${userId}/`, { headers: authHeader() });

const getProjectsForSelf = () =>
  axios.get(`${API_URL}/projects/user/`, { headers: authHeader() });

const updateUserProjectsList = (projectsData) => {
  const data = projectsData;

  const userId = data.id;
  return axios.put(`${API_URL}/projects/${userId}/`, data, {
    headers: authHeader(),
  });
};

const getProjectSummary = (projectId) => {
  return axios.get(`${API_URL}/projects/summary/${projectId}/`, {
    headers: authHeader(),
  });
};

const projectsServices = {
  getProjects,
  createProject,
  getProjectsForUser,
  getProjectsForSelf,
  updateUserProjectsList,
  getProjectSummary,
  updateProject,
  deleteProject,
};

export default projectsServices;
