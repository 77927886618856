import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    marginLeft: "auto",
  },
}));

export default function SubscriptionExpiredModal({
  display,
  handleClose,
  expirationStatus,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [t] = useTranslation("users");

  const handleNavigateToMyAccount = () => {
    navigate("/account");
  };

  const handleHideForOneWeek = () => {
    localStorage.setItem("subscription_will_expire_hidden", true);
    handleClose();
  };

  useEffect(() => {
    if (expirationStatus === "warning") {
      const subscriptionWillExpireHidden = localStorage.getItem(
        "subscription_will_expire_hidden"
      );
      if (subscriptionWillExpireHidden) {
        handleClose();
      }
    }
  }, [expirationStatus]);

  return (
    <Dialog open={display} fullWidth>
      <DialogTitle variant="h3">
        {expirationStatus === "warning" && t("subscription_will_expire")}
        {expirationStatus === "expired" && t("subscription_expired")}
      </DialogTitle>

      <DialogContent>
        {expirationStatus === "warning" && t("subscription_will_expire_text")}
        {expirationStatus === "expired" && t("subscription_expired_text")}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {expirationStatus === "warning" && (
          <Button
            variant="outlined"
            component="label"
            color="darkPrimary"
            onClick={handleHideForOneWeek}
          >
            {t("hide")}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          component="label"
          onClick={handleNavigateToMyAccount}
        >
          {t("my_account")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
