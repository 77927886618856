import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../Navigation/SideBar/SideBar";
import TopMenu from "../TopMenu/TopMenu";
import Banner from "../Feedback/Banner/Banner";

import { displayBanner } from "../../../slices/feedbackSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingRight: 240,
  },
  content: {
    height: "100%",
  },
}));

export default function MainLayout({ children }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [t] = useTranslation("common");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const isAdmin = useSelector((state) => state.account.isAdmin);
  const isTeamAdmin = useSelector((state) => state.account.isTeamAdmin);
  const organization = useSelector((state) => state.account.organization);

  useEffect(() => {
    if (
      isLoggedIn &&
      isAdmin &&
      organization.trial_expiration_date &&
      !organization.is_subscribed
    ) {
      const diffTime =
        new Date(organization.trial_expiration_date) - new Date();

      const days_left = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const trialBannerContent = {
        message: `${days_left} ${t("free_trial_expiration")}`,
        icon: null,
        button: {
          label: t("my_account"),
          action: () => navigate("/account"),
        },
      };
      dispatch(displayBanner(trialBannerContent));
    }
  }, [dispatch, isLoggedIn, isAdmin, organization]);

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <div className={classes.root}>
      <TopMenu onSidebarOpen={handleSidebarOpen} minimalLayout={false} />
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={"temporary"}
      />

      <main className={classes.content}>
        <Banner />
        {children}
      </main>
    </div>
  );
}

MainLayout.defaultProps = {
  children: {},
};
MainLayout.propTypes = {
  children: PropTypes.node,
};
