import React from "react";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";
import logo from "../../assets/images/logo.svg";

const useStyles = makeStyles({
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
    height: "1.75em",
    width: "1.75em",
  },
  iconRootSignup: {
    textAlign: "center",
    height: "8em",
    width: "8em",
  },
});

export default function Logo() {
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={logo} alt="logo" />
    </Icon>
  );
}

export function LogoSignup() {
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRootSignup }}>
      <img className={classes.imageIcon} src={logo} alt="logo" />
    </Icon>
  );
}
