import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

// Fiscal Years
const getFiscalYears = () =>
  axios.get(`${API_URL}/timesheets/fiscal-years/`, { headers: authHeader() });

const createFiscalYear = (fiscalYearData) => {
  const data = {
    date_from: fiscalYearData.dateFrom,
    date_to: fiscalYearData.dateTo,
    status: fiscalYearData.status,
    week_first_day: fiscalYearData.weekFirstDay,
  };

  return axios.post(`${API_URL}/timesheets/fiscal-years/`, data, {
    headers: authHeader(),
  });
};

// Timesheets
const getTimesheetsForUser = ({ userId, displayPreviousYears }) =>
  axios.get(`${API_URL}/timesheets/user/${userId}/`, {
    params: { "display-previous-years": displayPreviousYears },
    headers: authHeader(),
  });

const getTimesheetsForSelf = ({ displayPreviousYears }) =>
  axios.get(`${API_URL}/timesheets/user/`, {
    params: { "display-previous-years": displayPreviousYears },
    headers: authHeader(),
  });

const updateTimesheet = (timesheetData) => {
  const data = timesheetData;
  const timesheetId = timesheetData.id;
  return axios.put(`${API_URL}/timesheets/${timesheetId}/`, data, {
    headers: authHeader(),
  });
};

const getTimesheet = (timesheetId) => {
  return axios.get(`${API_URL}/timesheets/${timesheetId}/`, {
    headers: authHeader(),
  });
};

// Reminders
const sendReminderToOrganization = (date) => {
  const data = {
    date,
  };
  return axios.post(`${API_URL}/timesheets/reminder/organization/`, data, {
    headers: authHeader(),
  });
};

// Summary
const getSummaryForSelf = (date = null) => {
  const query = {
    date,
  };
  return axios.get(`${API_URL}/timesheets/summary/user/`, {
    params: query,
    headers: authHeader(),
  });
};

const getUserSummary = (userId) => {
  return axios.get(`${API_URL}/timesheets/summary/user/${userId}/`, {
    headers: authHeader(),
  });
};

const getTimesheetsToCountersign = (userId) => {
  return axios.get(`${API_URL}/timesheets/to-countersign/`, {
    headers: authHeader(),
  });
};

const timesheetServices = {
  getFiscalYears,
  createFiscalYear,
  getTimesheetsForUser,
  getTimesheetsForSelf,
  getTimesheet,
  updateTimesheet,
  sendReminderToOrganization,
  getSummaryForSelf,
  getUserSummary,
  getTimesheetsToCountersign,
};

export default timesheetServices;
