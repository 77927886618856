import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

export default function OverflowTooltip({
  id,
  defaultValue,
  value,
  className,
  label,
}) {
  const [open, setOpen] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    const overflow =
      !!textElementRef.current.value &&
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    if (!overflow) {
      setOpen(false);
    }
  });
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <label aria-hidden hidden htmlFor={id}>
        {label}
      </label>
      <Tooltip
        title={value}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <input
          ref={textElementRef}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          id={id}
          className={className}
          defaultValue={defaultValue}
          value={value || ""}
        />
      </Tooltip>
    </>
  );
}
