import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { formatDate } from "../../../utils/helpers";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: "10px",
  },
}));

export default function PublicHolidaysConfig({
  handlePrevious,
  handleNext,
  handleChangeConfig,
  config,
}) {
  const classes = useStyles();
  const [date, setDate] = useState("");
  const { t, i18n } = useTranslation("myAccount");
  const locale = i18n.language;

  const handleClickNext = () => {
    handleNext();
  };

  const handleChange = (newPublicHolidays) => {
    const newPublicHolidaysConfig = {
      ...config.publicHolidays,
      dates: newPublicHolidays,
    };
    handleChangeConfig("publicHolidays", newPublicHolidaysConfig);
  };

  const handleDeletePublicHoliday = (date) => {
    const newPublicHolidays = [...config.publicHolidays.dates];
    const index = newPublicHolidays.indexOf(date);
    if (index > -1) {
      newPublicHolidays.splice(index, 1); // 2nd parameter means remove one item only
    }
    handleChange(newPublicHolidays);
  };

  const handleAddPublicHoliday = () => {
    if (date) {
      const newPublicHolidays = [...config.publicHolidays.dates, date];
      handleChange(newPublicHolidays);
    }
  };

  const handleChangeDate = (event) => {
    const { value } = event.target;
    setDate(value);
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.public_holidays.1")}
              {` (${t("from")} `}
              {formatDate(config.fiscalYear.dateFrom, locale)} {` ${t("to")} `}
              {formatDate(config.fiscalYear.dateTo, locale)}
              {" )"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              type={"date"}
              variant="outlined"
              fullWidth
              id={"date"}
              name={"date"}
              onChange={handleChangeDate}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              component="label"
              color="darkPrimary"
              onClick={handleAddPublicHoliday}
              disabled={!date}
              sx={{ height: "100%" }}
            >
              {t("rules.add_public_holiday")}
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} marginleft={"0.05rem"}>
              {config.publicHolidays.dates.map((date) => (
                <Grid
                  item
                  xs={3}
                  key={date}
                  display={"flex"}
                  alignContent={"center"}
                >
                  <div className={classes.date}>{formatDate(date, locale)}</div>
                  <div>
                    <DeleteForeverIcon
                      color={"error"}
                      onClick={() => handleDeletePublicHoliday(date)}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          component="label"
          color="darkSecondary"
          onClick={handlePrevious}
        >
          {t("config_assistant.previous")}
        </Button>
        <LoadingButton
          variant="contained"
          component="label"
          color="primary"
          onClick={handleClickNext}
        >
          {t("config_assistant.next")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
