import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
}));

export default function ArchiveUserModal({
  display,
  handleClose,
  handleSave,
  handleCancel,
}) {
  const classes = useStyles();
  const [t] = useTranslation("users");

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      <DialogTitle variant="h3">
        {t("archiving.title")}{" "}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant={"body1"} marginBottom={2}>
          {t("archiving.confirmation")}
        </Typography>
        <InfoBox level={"info"}>
          <p>{t("archiving.info_1")}</p>
          <p>{t("archiving.info_2")}</p>
        </InfoBox>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="secondary"
          component="label"
          onClick={handleCancel}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          component="label"
          onClick={handleSave}
        >
          {t("archive")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
