import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { displayAlert } from "./feedbackSlice";

import AuthService from "../services/auth.service";
import { manageError } from "../utils/helpers";

const token = document.cookie
  .split("; ")
  .find((row) => row.startsWith("token="))
  ?.split("=")[1];
const hasAccount = JSON.parse(localStorage.getItem("hasAccount"));

export const signup = createAsyncThunk(
  "auth/register",
  async ({ firstName, lastName, email, password, organization }, thunkAPI) => {
    try {
      const response = await AuthService.signup(
        firstName,
        lastName,
        email,
        password,
        organization
      );
      return response.data;
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      return { user: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const resetPassword = createAsyncThunk(
  "auth/password/reset",
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.resetPassword(email);
      return response.data;
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetPasswordVerify = createAsyncThunk(
  "auth/password/reset/verify",
  async ({ code }, thunkAPI) => {
    try {
      const response = await AuthService.resetPasswordVerify(code);
      return response.data;
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetPasswordVerified = createAsyncThunk(
  "auth/password/reset/verify",
  async ({ code, newPassword }, thunkAPI) => {
    try {
      const response = await AuthService.resetPasswordVerified(
        code,
        newPassword
      );
      return response.data;
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = token
  ? {
      isLoggedIn: true,
      hasAccount: true,
    }
  : { isLoggedIn: false, hasAccount };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setHasAccount: (state, action) => {
      state.hasAccount = action.payload;
      localStorage.setItem("hasAccount", action.payload);
    },
    getHasAccount: (state, action) => {
      state.hasAccount = localStorage.getItem("hasAccount") === "true";
    },
  },
  extraReducers: {
    [signup.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [signup.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      const user = action.payload.user;
      document.cookie = `token=${user.token}; SameSite=None; Secure; Max-Age=31556952; SameSite=Strict`;
      state.isLoggedIn = true;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      localStorage.removeItem("self");
      localStorage.removeItem("user");
    },
    [resetPasswordVerify.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [resetPasswordVerify.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [resetPasswordVerified.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [resetPasswordVerified.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [resetPassword.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;

export const { setHasAccount, getHasAccount } = authSlice.actions;
